export class IconProvider {
  public static readonly LOGOUT: string =
  `
    <svg width="16" height="13" viewBox="0 0 16 13" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M15.5312 7.03125L10.2812 12.2812C9.8125 12.75 9 12.4375 9 11.75V8.75H4.75C4.3125 8.75 4 8.4375 4 8V5C4 4.59375 4.3125 4.25 4.75 4.25H9V1.25C9 0.59375 9.78125 0.25 10.2812 0.71875L15.5312 5.96875C15.8125 6.28125 15.8125 6.75 15.5312 7.03125ZM6 12.125C6 12.3438 5.8125 12.5 5.625 12.5H3C1.34375 12.5 0 11.1562 0 9.5V3.5C0 1.84375 1.34375 0.5 3 0.5H5.625C5.8125 0.5 6 0.6875 6 0.875V2.125C6 2.34375 5.8125 2.5 5.625 2.5H3C2.4375 2.5 2 2.96875 2 3.5V9.5C2 10.0625 2.4375 10.5 3 10.5H5.625C5.8125 10.5 6 10.6875 6 10.875V12.125Z" fill="#1A2430"/>
    </svg>
  `;

  public static readonly LOGIN_ERROR: string =
  `
    <svg width="100" height="89" viewBox="0 0 100 89" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M50 0.75C52.7734 0.75 55.332 2.21484 56.7383 4.61719L98.9258 76.4922C100.352 78.9141 100.352 81.9023 98.9648 84.3242C97.5781 86.7461 94.9804 88.25 92.1875 88.25H7.81249C5.01952 88.25 2.42187 86.7461 1.03515 84.3242C-0.351569 81.9023 -0.332038 78.8945 1.07421 76.4922L43.2617 4.61719C44.6679 2.21484 47.2265 0.75 50 0.75ZM50 25.75C47.4023 25.75 45.3125 27.8398 45.3125 30.4375V52.3125C45.3125 54.9102 47.4023 57 50 57C52.5976 57 54.6875 54.9102 54.6875 52.3125V30.4375C54.6875 27.8398 52.5976 25.75 50 25.75ZM56.25 69.5C56.25 67.8424 55.5915 66.2527 54.4194 65.0806C53.2473 63.9085 51.6576 63.25 50 63.25C48.3424 63.25 46.7527 63.9085 45.5806 65.0806C44.4085 66.2527 43.75 67.8424 43.75 69.5C43.75 71.1576 44.4085 72.7473 45.5806 73.9194C46.7527 75.0915 48.3424 75.75 50 75.75C51.6576 75.75 53.2473 75.0915 54.4194 73.9194C55.5915 72.7473 56.25 71.1576 56.25 69.5Z" fill="#D32F2F"/>
    </svg>
  `;
}