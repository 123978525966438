import { Location } from '@angular/common';
import { Component } from '@angular/core';
import { FormBuilder, FormControl, Validators } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import { BackendService } from 'src/app/services/backend.service';

import { MatDialog } from '@angular/material/dialog';
import { WellRequestLog } from 'src/app/model/well-request-log.model';
import { PermissionsService } from 'src/app/services/permissions.service';
import { SubmitPopupComponent } from '../../controls/popup/submit/submit-popup.component';
import { PopupData } from '../../model/popup.model';
import { WellDetailsComponent } from '../well-details/well-details.component';

import { WellRequestStatus_Options } from '../../model/status-enum.model';
import { RoutingService } from '../../services/routing.service';
import { EmailService } from '../../services/email.service';
import { MsalService } from '@azure/msal-angular';


@Component({
  selector: 'app-well-approve',
  templateUrl: './well-approve.component.html',
  styleUrls: ['./well-approve.component.css'],
  providers: [RoutingService]
})
export class WellApproveComponent extends WellDetailsComponent {
  constructor(
    _backendService: BackendService,
    _permissionService: PermissionsService,
    _routingService: RoutingService,
     _email: EmailService,
    _router: Router,
    _msalService: MsalService,
    _snackBar: MatSnackBar,
    _location: Location,
    _formBuilder: FormBuilder,
    private readonly _dialog: MatDialog) {
    super(_routingService, _snackBar, _formBuilder, _msalService,_backendService, _permissionService, _email);
    this.form.addControl('comments', new FormControl('', Validators.compose([Validators.maxLength(250)])))
  }

  openDialogApprove(): void {
    this.resetValidation(this.form?.controls["comments"]);

    let popupData: PopupData = this.getPopupData("Approve");
    const dialogRef = this._dialog.open(SubmitPopupComponent, { data: { popupData: popupData } });

    dialogRef.afterClosed().subscribe(result => {
      if (result && this.wellRequest?.id) {
        const statusToUpdate = WellRequestStatus_Options.ApprovedActive;
        this.createPendingRequest(statusToUpdate, this.wellRequest.id);
      } 
    });
  }

  openDialogReject() {
    if (!this.validate(this.form.controls["comments"]))
      return;

    let popupData: PopupData = this.getPopupData("Reject");
    const dialogRef = this._dialog.open(SubmitPopupComponent, { data: { popupData: popupData } });

    dialogRef.afterClosed().subscribe(result => {
      if (result)
        this.handelLogRequest((log: WellRequestLog) => this.backendService.rejectWellRequest(log),
          (log: WellRequestLog) => this.sendEmail(log));
    });
  }

  requestChangesSubmit(): void {
    if (!this.validate(this.form.controls["comments"]))
      return;

    this.handelLogRequest((log: WellRequestLog) => this.backendService.requestChanges(log),
      (log: WellRequestLog) => this.sendEmail(log));
  }

  private getPopupData(name: string): PopupData {
    let popupData = new PopupData();
    popupData.header = name + " Well Request";
    popupData.textInfo = `
      <span>
        Please note that after clicking <strong>`+ name.toUpperCase() +` REQUEST,<br/>
        additional changes</strong> to the request <strong>will not be possible.</strong>
      </span>
    `;
    popupData.textSubmit = "YES, "+ name.toUpperCase() +" REQUEST";
    return popupData;
  }

  onCommentsEnter(event: Event){
    event.preventDefault();
  }
}
