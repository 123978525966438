<div class="table-container">
  <div>
      <mat-label class="mat-headline-3">{{header}}</mat-label>
  </div>

  <div class="table-section">
      <div>
          <mat-form-field class="mat-input-d-3">
              <input matInput (keyup)="applyFilter($event)">
              <mat-icon matPrefix>
                  search
              </mat-icon>
          </mat-form-field>

          <button mat-raised-button
                  type="button" 
                  color="primary"
                  class="submit-button"
                  routerLink="/well-request/new">
              Request New Well
          </button>
      </div>

      <div class="mat-elevation-z8">
          <table mat-table class="full-width-table" [dataSource]="dataSource" matSort matSortActive="id" matSortDirection="desc" aria-label="Elements">

              <ng-container matColumnDef="id">
                  <th mat-header-cell *matHeaderCellDef mat-sort-header>REQUEST ID</th>
                  <td mat-cell *matCellDef="let row">{{ row.id }}</td>
              </ng-container>

              <ng-container matColumnDef="wellName">
                  <th mat-header-cell *matHeaderCellDef mat-sort-header>WELL NAME</th>
                  <td mat-cell class="apply-space" *matCellDef="let row">{{ row.wellName }}</td>
              </ng-container>

              <ng-container matColumnDef="userName">
                  <th mat-header-cell *matHeaderCellDef mat-sort-header>REQUESTED BY</th>
                  <td mat-cell *matCellDef="let row">{{ row.userName }}</td>
              </ng-container>
          
              <ng-container matColumnDef="date">
                  <th mat-header-cell *matHeaderCellDef mat-sort-header>DATE REQUEST</th>
                  <td mat-cell *matCellDef="let row">{{ row.date + "Z" | date:'yyyy-MM-dd HH:mm:ss' }}</td>
              </ng-container>
              <ng-container matColumnDef="status">
                  <th mat-header-cell *matHeaderCellDef mat-sort-header>STATUS</th>
                  <td mat-cell *matCellDef="let row">{{ row.status }}</td>
              </ng-container>

              <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
              <tr mat-row *matRowDef="let row; columns: displayedColumns;" class="row-hover" (click)="getRecord(row)"></tr>
          </table>

          <mat-paginator [pageSizeOptions]="[5, 10, 20]" aria-label="Select page"></mat-paginator>
      </div>
  </div>
</div>
