import { Injectable, Inject, OnDestroy } from "@angular/core";
import { PermissionsService } from "./permissions.service";
import { MsalService, MsalBroadcastService, MSAL_GUARD_CONFIG, MsalGuardConfiguration } from "@azure/msal-angular";
import { AccountInfo, EventMessage, EventType, InteractionStatus, RedirectRequest } from "@azure/msal-browser";
import { filter, takeUntil, Subject } from "rxjs";
import { environment } from '../../environments/environment';
import { Router } from "@angular/router";

@Injectable({
  providedIn: 'root'
})
export class AuthService implements OnDestroy {
  private readonly _destroying$ = new Subject<void>();
  
  private _initialized: boolean = false;
  private _isUserloggedIn: boolean = false;
  private _hasAccessToCemLive: boolean = false;

  constructor(
    @Inject(MSAL_GUARD_CONFIG) private _msalGuardConfig: MsalGuardConfiguration,
    private readonly _msalService: MsalService,
    private readonly _msalBroadcastService: MsalBroadcastService,
    private readonly _permissionsService: PermissionsService,
    private readonly _router: Router) {}

  init(): void {
    if (this._initialized)
      return;

    this.setIsUserloggedIn();

    this._msalService.instance.enableAccountStorageEvents(); // Optional - This will enable ACCOUNT_ADDED and ACCOUNT_REMOVED events emitted when a user logs in or out of another tab or window
    this._msalBroadcastService.msalSubject$
      .pipe(
        filter((msg: EventMessage) => msg.eventType === EventType.ACCOUNT_ADDED || msg.eventType === EventType.ACCOUNT_REMOVED),
      )
      .subscribe(() => {
        if (this._msalService.instance.getAllAccounts().length === 0) {
          window.location.pathname = "/";
        } else {
          this.setIsUserloggedIn();
        }
      });

    this._msalBroadcastService.msalSubject$
      .pipe(
        filter((msg: EventMessage) => msg.eventType === EventType.LOGIN_SUCCESS),
      )
      .subscribe((value: any) => {
        this.setActiveAccount(value?.payload?.account as AccountInfo);
      });
    
    this._msalBroadcastService.inProgress$
      .pipe(
        filter((status: InteractionStatus) => status === InteractionStatus.None),
        takeUntil(this._destroying$)
      )
      .subscribe(() => {
        this.setIsUserloggedIn();
      });

      this._initialized = true;
  }

  private setIsUserloggedIn(): void {
    this._isUserloggedIn = this._msalService.instance.getAllAccounts().length > 0;
    if (this._isUserloggedIn)
      this.validateUserPermissions();
  }

  private validateUserPermissions(): void {
    this._permissionsService.GetUserPermission().subscribe({
      next: () => {
        this._hasAccessToCemLive = true;
        console.log("The User has CEMLive (and VIDA) access")
      },
      error: (err) => {
        if (err.status == 403)
          console.error("The User doesn't have CEMLive (and VIDA) access");
        else
          console.error("Unable to get the user Permissions");

        this._hasAccessToCemLive = false;
        this._permissionsService.Clear();
        this._router.navigateByUrl('/access-denied');
      }
    });
  }

  private setActiveAccount(accountInfo: AccountInfo | null): void {
    if (accountInfo && this._msalService.instance.getAllAccounts().length > 0) {
      this._msalService.instance.setActiveAccount(accountInfo);
    }
  }

  isUserloggedIn(): boolean {
    return this._isUserloggedIn;
  }

  hasAccessToCemLive(): boolean {
    return this._hasAccessToCemLive;
  }

  loginRedirect(): void {
    if (this._msalGuardConfig.authRequest) {
      this._msalService.loginRedirect({...this._msalGuardConfig.authRequest, prompt: "select_account", redirectStartPage: '/'} as RedirectRequest);
    } else {
      this._msalService.loginRedirect();
    }
  }

  logout(): void {
    this._msalService.logoutRedirect({
      postLogoutRedirectUri: environment.msalConfig.auth.redirect
    });
  }

  ngOnDestroy(): void {
    this._destroying$.next(undefined);
    this._destroying$.complete();
  }
}