export enum WellRequestStatus_Options {
  Created = 1,
  Resubmited = 2,
  Cancelled_By_User = 11,
  Cancelled_By_Admin = 12,
  Request_Changes = 13,
  Approved = 20,
  ApprovedActive = 21,
  ApprovedInactive = 22,
}
