import { Component, OnInit } from '@angular/core';
import { AuthService } from '../services/auth.service';

@Component({
  selector: 'app-shell',
  templateUrl: './shell.component.html'
})
export class ShellComponent implements OnInit {
  isIframe = false;

  constructor(private readonly _authService: AuthService) {}

  ngOnInit(): void {
    this.isIframe = window !== window.parent && !window.opener; // Remove this line to use Angular Universal
  }

  isUserloggedIn(): boolean {
    return this._authService.isUserloggedIn();
  }
  
  hasAccessToCemLive(): boolean {
    return this._authService.hasAccessToCemLive();
  }

  login(): void {
    this._authService.loginRedirect();
  }

  logout(): void {
    this._authService.logout();
  }
}
