import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ErrorHandlerService {
  private _newErrorDetected: Subject<IError> = new Subject();
  public static ViewingTimeInSeconds: number = 30;

  constructor() { }


  get errorDetected$(): Observable<IError> {
    return this._newErrorDetected.asObservable();
  }

  notify(error: IError): void {
    this._newErrorDetected.next(error);
  }
  notify_ErrorDetails(message: string, source: string, viewingTimeInSeconds?: number/*, isModal?: boolean*/): void {
    this.notify(new Error(message, source, viewingTimeInSeconds ?? ErrorHandlerService.ViewingTimeInSeconds/*, isModal*/));
  }
}

export interface IError {
  message?: string;
  source?: string;
  date?: Date;
  viewingTimeInSeconds: number;
  //isModal?: boolean;
}
export class Error implements IError {
  message: string | undefined;
  source: string | undefined;
  date: Date | undefined;
  viewingTimeInSeconds: number;
  //isModal: boolean | undefined;

  constructor(message: string, source: string, viewingTimeInSeconds?: number/*, isModal?: boolean*/) {
    this.message = message;
    this.source = source;
    this.date = new Date();
    //this.isModal = isModal ?? false;
    this.viewingTimeInSeconds = viewingTimeInSeconds ?? 0;
  }
}
