<div class="header-container">
  <h3 class="mat-headline-3 apply-space">{{wellRequest?.name}}</h3>
  <mat-grid-list class="header-list" cols="2" rowHeight="80px" gutterSize="2px" > 
    <mat-grid-tile colspan="1">
        <app-status-select [wellRequest]="wellRequest"></app-status-select>
    </mat-grid-tile>

    <mat-grid-tile colspan="1">
      <div class="history-button-container">
        <a mat-raised-button type="button" class="history-button accent-button" color="accent" (click)="openHistoryLog()">History Log</a>
      </div>
    </mat-grid-tile>
  </mat-grid-list>
</div>
