import { Component, Input } from "@angular/core";
import { IStatus } from "src/app/model/status.model";

@Component({
  selector: 'app-status-icon',
  templateUrl: './status-icon.component.html',
  styleUrls: ['../status-select.component.css']
})
export class StatusIconComponent {
  @Input()
  status: IStatus | undefined;
}