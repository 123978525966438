import { SubmitPopupComponent } from "../../../controls/popup/submit/submit-popup.component";
import { PopupData } from "../../../model/popup.model";
import { Observable } from "rxjs";
import { MatDialog } from "@angular/material/dialog";
import { IGroup } from "../../../model/group.model";
import { WellRequestComponent } from "../well-request.component";
import { ICountry } from "../../../model/country.model";

export class WellRequestBaseHandler {
    protected wellRequestComp!: WellRequestComponent;
    protected _dialog!: MatDialog;

    constructor(wellRequestComp: WellRequestComponent) {
        this.wellRequestComp = wellRequestComp!;
        this._dialog = wellRequestComp._dialog!;
    }   

    getRigsByRegion(regionId: string) {
        var regionGroups = this.wellRequestComp.groups.filter(x => x.regionId == Number.parseInt(regionId));
        this.getRigsByGroups(regionGroups);
    }

    getRigsByGroups(groups: IGroup[] | undefined) {
        if (!groups)
            return;

        this.wellRequestComp.filteredRigs = this.wellRequestComp.rigs
            .filter(x => groups
                .map(g => g.id)
                .includes(x.groupId));
    }

    getRigsByCountry(country: ICountry) {
        if (this.isCountryMapped(Number.parseInt(country.id ?? "-1"))) {
            let groups = this.wellRequestComp.groups.filter(x => x.countryId == Number.parseInt(country?.id ?? "-1"));
            this.getRigsByGroups(groups);
        }
        else
            this.getRigsByRegion(country.regionId?.toString() ?? "-1");
    }

    resetGroups() {
        this.wellRequestComp.groupControl.reset();
        this.wellRequestComp.selectedGroups = [];
        this.wellRequestComp.rigControl.reset('');
        this.resetAdmins(this.wellRequestComp.selectedGroups);
    }

    resetAdmins(groups: IGroup[] | undefined) {
        if (!groups || groups?.length == 0) {
            this.wellRequestComp.adminControl.reset();
            this.wellRequestComp.selectedAdmins = [];
            this.wellRequestComp.filteredAdmins = [];
            return;
        }
    }

    isCountryMapped(countryId: number | undefined): boolean {
        return this.wellRequestComp.groups.filter(x => x.countryId == countryId).length > 0;
    }

    countryFilter(countryName: string, regionId: number) {
        let countriesInRegion: ICountry[] = this.wellRequestComp.getFilteredCountries(regionId);
        this.wellRequestComp.filteredCountries = countriesInRegion.filter(x => x.name?.toLowerCase()?.includes((countryName as string)?.toLowerCase()));
    }

    openDialogUpdate(): Observable<any> {
      let popupUpdateData = new PopupData();
      popupUpdateData.header = "Updating related fields";
      popupUpdateData.textInfo = `
        <span>
          Please note that updating <strong>REGION, COUNTRY OR<br/>
          GROUP NAME </strong>inputs will results in the <strong>deletion of the<br/>
          current list of admins.</strong> Consequently, these admins <strong>will<br/>
          no longer be reachable</strong> or be able to <strong>take action on<br/>
          this request.</strong>
        </span>
      `;
      popupUpdateData.textSubmit = "YES, UPDATE FIELD(S)";
      const dialogRef = this._dialog.open(SubmitPopupComponent, { data: { popupData: popupUpdateData } });
      return dialogRef.afterClosed();
    }
}
