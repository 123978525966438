import { Component, OnInit } from '@angular/core';
import { MsalBroadcastService, MsalService } from '@azure/msal-angular';
import { AuthenticationResult, EventMessage, EventType, InteractionStatus } from '@azure/msal-browser';
import { filter } from 'rxjs/operators';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css']
})
export class HomeComponent implements OnInit {
  private _isUserloggedIn: boolean = false;

  constructor(
    private _msalService: MsalService,
    private _msalBroadcastService: MsalBroadcastService
  ) { }

  ngOnInit(): void {
    this._msalBroadcastService.msalSubject$
      .pipe(
        filter((msg: EventMessage) => msg.eventType === EventType.LOGIN_SUCCESS),
      )
      .subscribe((result: EventMessage) => {
        console.log(result);
        const payload = result.payload as AuthenticationResult;
        this._msalService.instance.setActiveAccount(payload.account);
      });
    
    this._msalBroadcastService.inProgress$
      .pipe(
        filter((status: InteractionStatus) => status === InteractionStatus.None)
      )
      .subscribe(() => {
        this.setIsUserloggedIn();
      });    
  }

  setIsUserloggedIn() {
    this._isUserloggedIn = this._msalService.instance.getAllAccounts().length > 0;
  }
  isUserloggedIn(): boolean {
    return this._isUserloggedIn;
  }
}
