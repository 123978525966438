import { WellRequestStatus_Options } from "./status-enum.model";

export class EmailInfo {
  wellRequestId: string | undefined;
  emailToken: string | undefined;
  userLink: string | undefined;
  adminLink: string | undefined;
  isForAdmin: boolean | undefined;
  statusAfterRts: WellRequestStatus_Options | undefined;
  isDelay: boolean | undefined;
  adminsHallIds: string[] | undefined;
}
