import { IGroup } from "./group.model";
import { WellRequestStatus_Options } from "./status-enum.model";
import { WellRequestAdmin } from "./well-request-admin.model";
import { WellRequestLog } from "./well-request-log.model";

export class WellRequest {
  id: bigint | undefined;
  name: string | undefined;
  rtsEnvironmentId: string | undefined;
  rtsName: string | undefined;
  regionId: number | undefined;
  region: string | undefined;
  countryId: number | undefined;
  country: string | undefined;
  state: string | undefined;
  district: string | undefined;
  operatorId: string | undefined;
  operatorName: string | undefined;
  rigId: string | undefined;
  rigName: string | undefined;
  latitude: number | undefined;
  longitude: number | undefined;
  userId: string | undefined;
  currentStatusId: WellRequestStatus_Options | undefined;
  wellRequestStatusId: WellRequestStatus_Options | undefined;
  isInRts: boolean | undefined;
  wellRequestLogs: WellRequestLog[] | undefined;
  groups: IGroup[] | undefined;
  admins: WellRequestAdmin[] | undefined;
}
