import { Component, OnInit, AfterViewInit, ViewChild } from '@angular/core';
import { BackendService } from 'src/app/services/backend.service';
import { WellRequest } from 'src/app/model/well-request.model';
import { IStatus } from 'src/app/model/status.model';
import { Observable, switchMap } from 'rxjs';
import { ParamMap } from '@angular/router';
import { RoutingService } from '../../services/routing.service';
import { StatusHelperService } from 'src/app/services/status-helper.service';
import { MatTableDataSource } from '@angular/material/table';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';

type HistoryLog = {
  by: string | undefined;
  status: string | undefined,
  date: Date | undefined
}

@Component({
  selector: 'app-well-history-log',
  templateUrl: './well-history-log.component.html',
  styleUrls: ['./well-history-log.component.css'],
  providers: [RoutingService]
})
export class WellHistoryLogComponent implements OnInit, AfterViewInit {

  @ViewChild(MatPaginator)
  paginator!: MatPaginator;

  @ViewChild(MatSort)
  sort!: MatSort

  wellRequest: WellRequest | undefined;
  preselectedStatus: IStatus | undefined;
  dataSource = new MatTableDataSource<HistoryLog>();
  displayedColumns = ["by", "status", "date", "adminIds"];

  constructor(private readonly _routingService: RoutingService, private readonly _backendService: BackendService) { }

  ngOnInit(): void {
    this.loadWell();
  }

  private loadWell(): void {
    let wellRequest$: Observable<WellRequest> = this._routingService.activatedRoute.paramMap.pipe(
      switchMap((params: ParamMap) =>
        this._backendService.getWellRequest(params.get('id') ?? ""))
    );

    wellRequest$.subscribe((wellRequest: WellRequest) => {
      if (!wellRequest?.wellRequestLogs)
        return;

      this.wellRequest = wellRequest;
      this.preselectedStatus = StatusHelperService.GetStatus(this.wellRequest?.currentStatusId);
      let logs: HistoryLog[] = this.wellRequest.wellRequestLogs!
        .map<HistoryLog>(log => {
          return {
            by: log.user?.firstName + " " + log.user?.lastName,
            status: log.wellRequestStatus?.displayedAs,
            date: log.date,
            adminIds: log.adminIds?.join(', ')
          }
        })
        .sort((a, b) => new Date(b.date!).getTime() - new Date(a.date!).getTime());

      this.dataSource.data = logs;
    });
  }

  ngAfterViewInit() {
    this.intiPaginator();
  }

  protected intiPaginator() {
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
  }
}
