import { AfterViewInit, ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, Input, OnChanges, Output } from '@angular/core';
import { PermissionsService } from '../services/permissions.service';
import { Router } from '@angular/router';
import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';
import { IconProvider } from '../providers/icon-provider';

@Component({
  selector: 'app-menu',
  templateUrl: './menu.component.html',
  styleUrls: ['./menu.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class MenuComponent implements AfterViewInit, OnChanges {
  @Input() isUserloggedIn: boolean | undefined;
  @Input() hasAccessToCemLive: boolean | undefined; 
  @Output() loginAction = new EventEmitter();
  @Output() logoutAction = new EventEmitter();

  public enable_wellRequestOptions: boolean = false;
  private _isAdmin: boolean = false;
  isMenuVisible: boolean | undefined;
  activeLink: string = "";
  routes: any = [];

  constructor(
    private readonly _changeDetectorRef: ChangeDetectorRef,
    private readonly _permissionsService: PermissionsService,
    private readonly _router: Router,
    iconRegistry: MatIconRegistry,
    sanitizer: DomSanitizer) {
    this.resetRoutes(true);
    this.registerEvents();
    iconRegistry.addSvgIconLiteral("logout", sanitizer.bypassSecurityTrustHtml(IconProvider.LOGOUT));
  }

  ngAfterViewInit(): void {
    this._changeDetectorRef.markForCheck();
  }

  ngOnChanges(): void {
    this.CheckPermissions();
  }

  private registerEvents(): void {
    this._router.events.subscribe(() => this.onRouteChanged());
  }

  private onRouteChanged(): void {
    const currentUrl = this._router.url;
    let currentRouteIndex = this.routes.findIndex((x: any) => x.route === currentUrl);
    this.isMenuVisible = currentUrl === "/" || currentRouteIndex >= 0;

    if (currentRouteIndex >= 0) {
      this.routes.forEach((item: any, index: number) => {
        item.active = index === currentRouteIndex;
      });
    }

    this._changeDetectorRef.detectChanges();
  }

  login(): void {
    this.loginAction.emit();
  }
  logout(): void {
    this.logoutAction.emit();
  }

  private resetRoutes(beforeInit: boolean = false) {
    this.routes = [
      { route: "/well-request/all", text: "ALL REQUESTS", disabled: !this.enable_wellRequestOptions, active: false, roles: ['admin', 'user'] },
      { route: "/well-request/my", text: "MY REQUESTS", disabled: !this.enable_wellRequestOptions, active: false, roles: ['admin', 'user'] },
      { route: "/well-request/pending", text: "PENDING REQUESTS", disabled: !this.enable_wellRequestOptions, active: false, roles: ['admin'] }
    ];
    if (!beforeInit)
      this._changeDetectorRef.detectChanges();
  }

  private CheckPermissions(): void {
    if (this.isUserloggedIn && this.hasAccessToCemLive) {
      this._permissionsService.CheckUserPermission('WellRequests_CanManage')
        .then(_ => this.enable_wellRequestOptions = _)
        .catch(() => this.enable_wellRequestOptions = false)
        .finally(() => this.resetRoutes());

      this._permissionsService.CheckUserPermission('WellRequests_AdminInGroups')
        .then(x => this._isAdmin = x).catch(x => this._isAdmin = false);
    }
    else {
      this.enable_wellRequestOptions = false;
      this.resetRoutes();
    }
  }

   showTab(roles: string[]): boolean {

    var currentRole = this._isAdmin ? 'admin' : 'user'; 

    return roles.includes(currentRole);;
    }
}
