export const environment = {
  version: {
    appVersion: require('../../package.json').version,
    buildNumber: '20240912.1',
    suffix: '-dev',
  },
  production: false,
  msalConfig: {
    auth: {
      clientId: '0c6dde44-dd93-491f-bd00-a60aacbb7f6f',
      authority: 'https://login.microsoftonline.com/b7be7686-6f97-4db7-9081-a23cf09a96b5',
      redirect: 'https://dev.cemlive-management.ienergy.halliburton.com/'
    }
  },
  msGraphConfig: {
    scopes: ['.default'],
    uri: 'https://graph.microsoft.com/v1.0/me'
  },
  backendConfig: {
    scopes: ['api://5e14547d-eb7c-4e0b-be08-12bfb1ace29c/WellCatalog'],
    url: 'https://dev.cemlive-management-api.ienergy.halliburton.com/'
  }
};
