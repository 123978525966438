<ng-template #emptyField><span>N/A</span></ng-template>
<div>
  <app-back-button></app-back-button>
  <form class="form" [formGroup]="form">
    <div *ngIf="!editForm">
      <h3 class="mat-headline-3">{{header}}</h3>
    </div>
    <div *ngIf="editForm">
      <app-well-details-header [wellRequest]="wellRequest"></app-well-details-header>
      <div class="cancel-button">
        <app-cancel-request-button *ngIf="!wellRequest?.isInRts" [wellRequestId]="wellRequest?.id"></app-cancel-request-button>
      </div>
      <app-well-details-admin></app-well-details-admin>
      <mat-divider></mat-divider>
    </div>
    <div class="mat-input-d-3" [ngClass]="{'form-content': !editForm}">
      <div *ngIf="!wellRequest?.isInRts">
        <mat-label for="name">Well Name</mat-label><br />
        <mat-form-field appearance="outline" subscriptSizing="dynamic">
          <input matInput
                 name="name"
                 formControlName="name"
                 maxlength="50"
                 placeholder="Enter well name..."
                 required>
        </mat-form-field>
      </div>
      <div>
        <mat-label for="wellEnvironment">RTS Environment</mat-label><br />
        <mat-form-field appearance="outline" subscriptSizing="dynamic">
          <mat-select name="wellEnvironment" formControlName="wellEnvironment" required>
            <mat-option *ngFor="let environment of wellEnvironments" [value]="environment.id">{{environment.name}}</mat-option>
          </mat-select>
        </mat-form-field>
      </div>
      <div>
        <app-select label="Region"
                    placeholder="Enter region..."
                    [dataSource]="regionDataForDropdown"
                    formControlName="region"
                    app-autocomplete-validator
                    required>
        </app-select>
      </div>
      <div>
        <app-select label="Country"
                    placeholder="Enter country..."
                    [dataSource]="filteredCountries"
                    formControlName="country"
                    app-autocomplete-validator
                    required>
        </app-select>
      </div>
      <div>
        <app-chip #chipComponent label="Group Name"
                  placeholder="Enter group..."
                  [dataSource]="filteredGroups"
                  (selectedItemsEvent)="groupsSelectionChanged($event)"
                  (removeEvent)="groupsRemoveChanged($event)"
                  [selectedSourceItems]="selectedGroups"
                  formControlName="group"
                  [closeEvent] = "closeEvent"
                  app-autocomplete-validator
                  required>

        </app-chip>
      </div>
      <div class="nwa-info-item">
        <mat-label>NWA</mat-label>
        <ng-container *ngIf="selectedGroups?.length; else emptyField">
          <span>
            <ng-container *ngFor="let group of uniqueNWA; let last = last">
              {{ group.nwa }}<span style="padding:0px; margin:0px" *ngIf="!last">, </span>
            </ng-container>
          </span>
        </ng-container>
      </div>

      <div>
        <mat-label for="state">State</mat-label><span>&nbsp;(optional)</span><br />
        <mat-form-field appearance="outline" subscriptSizing="dynamic">
          <input matInput
                 name="state"
                 formControlName="state"
                 maxlength="50"
                 placeholder="Enter state...">
        </mat-form-field>
      </div>
      <div>
        <mat-label for="district">District</mat-label><span>&nbsp;(optional)</span><br />
        <mat-form-field appearance="outline" subscriptSizing="dynamic">
          <input matInput
                 name="district"
                 formControlName="district"
                 maxlength="50"
                 placeholder="Enter district...">
        </mat-form-field>
      </div>
      <div>
        <app-select label="Operator"
                    placeholder="Enter operator..."
                    [dataSource]="operators"
                    formControlName="operator"
                    app-autocomplete-validator
                    required>
        </app-select>
      </div>
      <div>
        <app-select label="Rig"
                    placeholder="Enter rig..."
                    [dataSource]="filteredRigs"
                    formControlName="rig"
                    app-autocomplete-validator
                    required>
        </app-select>
      </div>
      <mat-grid-list cols="2" rowHeight="100px" gutterSize="30px">
        <mat-grid-tile>
          <div>
            <mat-label for="latitude">Latitude</mat-label><br />
            <mat-form-field appearance="outline" subscriptSizing="dynamic">
              <input matInput
                     name="latitude"
                     formControlName="latitude"
                     placeholder="Enter latitude..."
                     decimaNumber=6
                     required>
            </mat-form-field>
          </div>
        </mat-grid-tile>
        <mat-grid-tile>
          <div>
            <mat-label for="longitude">Longitude</mat-label><br />
            <mat-form-field appearance="outline" subscriptSizing="dynamic">
              <input matInput
                     name="longitude"
                     formControlName="longitude"
                     placeholder="Enter longitude..."
                     decimaNumber=6
                     required>
            </mat-form-field>
          </div>
        </mat-grid-tile>
      </mat-grid-list>

      <div>
        <div style="margin:0px 0px -28px 50px;">
          <mat-icon matTooltip="A group name selection is needed to enable admin list" style="font-size: 21px; color: blue">info</mat-icon>
        </div>
        <app-chip #chipComponent label="Admin"
                  placeholder="Enter admin..."
                  [dataSource]="filteredAdmins"
                  [selectedSourceItems]="selectedAdmins"
                  [isRemovableFunc]="isAdminRemovable"
                  [count]=5
                  formControlName="admin"
                  app-autocomplete-validator
                  required>
        </app-chip>
      </div>
      <app-well-details-comments *ngIf="editForm"></app-well-details-comments>
    </div>
    <app-submit-cancel-button [disableSubmit]="false"  [form]="form" [popupSubmitData] ="popupSubmitData" (submitedEvent)="submitHandler($event)"></app-submit-cancel-button>
  </form>
</div>
