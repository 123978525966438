import { Component } from '@angular/core';
import { Location } from '@angular/common';
import { RoutingService } from '../../../services/routing.service';

@Component({
  selector: 'app-back-button',
  templateUrl: './back-button.component.html',
  styleUrls: ['./back-button.component.css'],
  providers: [RoutingService]
})
export class BackButtonComponent {
  constructor(private _routingService: RoutingService) {
  }

  backClicked() {
    this._routingService.back();
  }
}
