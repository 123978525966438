import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ScrollingModule } from '@angular/cdk/scrolling';

import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon'
import { MatInputModule } from '@angular/material/input';
import { MatListModule } from '@angular/material/list';
import { MatMenuModule } from '@angular/material/menu';
import { MatSelectModule } from '@angular/material/select';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatTabsModule } from '@angular/material/tabs';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatGridListModule} from '@angular/material/grid-list';
import { MatCardModule } from '@angular/material/card';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatChipsModule } from '@angular/material/chips';
import { NoopAnimationsModule, BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MatTooltipModule } from '@angular/material/tooltip';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HomeComponent } from './home/home.component';
import { ShellComponent } from './shell/shell.component';
import { FooterComponent } from './common/footer/footer.component';
import { ErrorUIComponent } from './common/error-ui/error-ui.component';
import { PageNotFoundComponent } from './common/page-not-found/page-not-found.component';
import { ProfileComponent } from './profile/profile.component';
import { MenuComponent } from './menu/menu.component';
import { SelectComponent } from './controls/select/select.component';
import { StatusSelectComponent } from './controls/select/status/status-select.component';
import { StatusIconComponent } from './controls/select/status/status-icon/status-icon.component';
import { CancelRequestButtonComponent } from './controls/buttons/cancel-request-button/cancel-request-button.component';
import { SubmitCancelButtonComponent } from './controls/buttons/submit-cancel-button/submit-cancel-button.component';

import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { IPublicClientApplication, PublicClientApplication, InteractionType, BrowserCacheLocation, LogLevel } from '@azure/msal-browser';
import { MsalGuard, MsalInterceptor, MsalBroadcastService, MsalInterceptorConfiguration, MsalModule, MsalService, MSAL_GUARD_CONFIG, MSAL_INSTANCE, MSAL_INTERCEPTOR_CONFIG, MsalGuardConfiguration, MsalRedirectComponent } from '@azure/msal-angular';
import { LoginFailedComponent } from './common/login-failed/login-failed.component';
import { AccessDeniedComponent } from './common/access-denied/access-denied.component';
import { environment } from 'src/environments/environment';
import { WellRequestComponent } from './wells/well-request/well-request.component';
import { WellApproveComponent } from './wells/well-approve/well-approve.component';
import { BackButtonComponent } from './controls/buttons/back-button/back-button.component';
import { DecimaNumberDirective } from './directives/decimal-number.directive';
import { AutocompleteValidatorDirective } from './directives/autocomplete-validator.directive';
import { MatTableModule } from '@angular/material/table';
import { MatDialogModule } from '@angular/material/dialog'
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatSortModule } from '@angular/material/sort';
import { MatDividerModule } from '@angular/material/divider';
import { WellRequestTableAllComponent } from './wells/well-request-table/well-request-table-all.component';
import { WellDetailsComponent } from './wells/well-details/well-details.component';
import { WellDetailsHeaderComponent } from './wells/well-details/well-details-header/well-details-header.component';
import { WellDetailsAdminComponent } from './wells/well-details/well-details-admin/well-details-admin.component';
import { WellDetailsInfoComponent } from './wells/well-details/well-details-info/well-details-info.component';
import { WellRequestTableMyComponent } from './wells/well-request-table/well-request-table-my.component';
import { SubmitPopupComponent } from './controls/popup/submit/submit-popup.component';
import { WellRequestTablePendingComponent } from './wells/well-request-table/well-request-table-pending.component';
import { WellAdminsDetailsComponent } from './wells/well-admin-details/well-admin-details.component';
import { WellDetailsCommentsComponent } from './wells/well-details/well-details-comments/well-details-comments.component';
import { WellHistoryLogComponent } from './wells/well-history-log/well-history-log.component';
import { RoutingService } from './services/routing.service';
import { EmailService } from './services/email.service';
import { ChipComponent } from './controls/chip/chip.component';

export function loggerCallback(logLevel: LogLevel, message: string) {
  console.log(message);
}

export function MSALInstanceFactory(): IPublicClientApplication {
  return new PublicClientApplication({
    auth: {
      clientId: environment.msalConfig.auth.clientId,
      authority: environment.msalConfig.auth.authority,
      redirectUri: environment.msalConfig.auth.redirect,
      postLogoutRedirectUri: environment.msalConfig.auth.redirect
    },
    cache: {
      cacheLocation: BrowserCacheLocation.LocalStorage
    },
    system: {
      allowNativeBroker: false, // Disables WAM Broker
      loggerOptions: {
        loggerCallback,
        logLevel: LogLevel.Info,
        piiLoggingEnabled: false
      }
    }
  });
}

export function MSALInterceptorConfigFactory(): MsalInterceptorConfiguration {
  const protectedResourceMap = new Map<string, Array<string>>();
  protectedResourceMap.set(environment.msGraphConfig.uri, environment.msGraphConfig.scopes);
  protectedResourceMap.set(environment.backendConfig.url, environment.backendConfig.scopes);

  return {
    interactionType: InteractionType.Redirect,
    protectedResourceMap
  };
}

export function MSALGuardConfigFactory(): MsalGuardConfiguration {
  return { 
    interactionType: InteractionType.Redirect,
    authRequest: {
      scopes: [...environment.msGraphConfig.scopes]
    },
    loginFailedRoute: '/login-failed'
  };
}

@NgModule({
  declarations: [
    AppComponent,
    HomeComponent,
    ShellComponent,
    ProfileComponent,
    LoginFailedComponent,
    AccessDeniedComponent,
    FooterComponent,
    PageNotFoundComponent,
    ErrorUIComponent,
    MenuComponent,
    WellRequestComponent,
    WellApproveComponent,
    WellAdminsDetailsComponent,
    WellDetailsComponent,
    WellDetailsHeaderComponent,
    WellDetailsAdminComponent,
    WellDetailsInfoComponent,
    BackButtonComponent,
    SubmitPopupComponent,
    DecimaNumberDirective,
    AutocompleteValidatorDirective,
    WellRequestTableAllComponent,
    WellRequestTableMyComponent,
    WellRequestTablePendingComponent,
    SelectComponent,
    StatusSelectComponent,
    StatusIconComponent,
    CancelRequestButtonComponent,
    SubmitCancelButtonComponent,
    WellDetailsCommentsComponent,
    WellHistoryLogComponent,
    ChipComponent
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    NoopAnimationsModule, // Animations cause delay which interfere with E2E tests
    AppRoutingModule,
    ScrollingModule,
    FormsModule,
    HttpClientModule,
    MatButtonModule,
    MatIconModule,
    MatCardModule,
    MatFormFieldModule,
    MatAutocompleteModule,
    MatChipsModule,
    MatTooltipModule,
    MatInputModule,
    MatListModule,
    MatMenuModule,
    MatSelectModule,
    MatSnackBarModule,
    MatTabsModule,
    MatDividerModule,
    MatToolbarModule,
    MatGridListModule,
    MsalModule,
    ReactiveFormsModule, 
    MatTableModule,
    MatDialogModule,
    MatPaginatorModule,
    MatSortModule,
  ],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: MsalInterceptor,
      multi: true
    },
    {
      provide: MSAL_INSTANCE,
      useFactory: MSALInstanceFactory
    },
    {
      provide: MSAL_GUARD_CONFIG,
      useFactory: MSALGuardConfigFactory
    },
    {
      provide: MSAL_INTERCEPTOR_CONFIG,
      useFactory: MSALInterceptorConfigFactory
    },
    MsalService,
    MsalGuard,
    MsalBroadcastService,
    RoutingService,
    EmailService
  ],
  bootstrap: [AppComponent, MsalRedirectComponent]
})
export class AppModule { }
