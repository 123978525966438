import { Component, Input } from '@angular/core';
import { SubmitPopupComponent } from '../../popup/submit/submit-popup.component';
import { MatDialog } from '@angular/material/dialog';
import { BackendService } from 'src/app/services/backend.service';
import { Location } from '@angular/common';
import { PopupData } from 'src/app/model/popup.model';
import { RoutingService } from '../../../services/routing.service';
import { EmailService } from '../../../services/email.service';

@Component({
  selector: 'app-cancel-request-button',
  templateUrl: './cancel-request-button.component.html',
  styleUrls: ['./cancel-request-button.component.css'],
  providers: [RoutingService]
})
export class CancelRequestButtonComponent {
  @Input()
  wellRequestId: bigint | undefined;

  private _data: PopupData | undefined;

  constructor(
    private readonly _dialog: MatDialog,
    private readonly _emailService: EmailService,
    private readonly _backendService: BackendService,
    private readonly _routingService: RoutingService) {
      this.initData();
  }

  private initData() {
    this._data = new PopupData();
    this._data.header = "Cancel Request";
    this._data.textInfo = `
      <span>
        The <strong>cancel request action</strong> 
        will <strong>cancel</strong> the request.<br/>
        Please confirm if you want to proceed.
      </span>
    `;
    this._data.textSubmit = "YES, CANCEL REQUEST"
  }

  openDialogCancell() {
    const dialogRef = this._dialog.open(SubmitPopupComponent, { data: { popupData: this._data } });
    dialogRef.afterClosed().subscribe(result => {
      if (result)
        this.cancelPopup()
    });
  }

  private cancelPopup() {
    if (!this.wellRequestId)
      return;

    this._backendService.cancelWellRequest(this.wellRequestId.toString()).subscribe({
      next: () => {
        if (this.wellRequestId)
          this._emailService.EmailNotifyAll(BigInt(this.wellRequestId), undefined);

        this._routingService.cancelRedirect();
      }
    });  
  }
}
