import { Component, Input } from '@angular/core';
import { WellRequest } from 'src/app/model/well-request.model';

@Component({
  selector: 'app-well-details-info',
  templateUrl: './well-details-info.component.html',
  styleUrls: ['../well-details.component.css'],
})
export class WellDetailsInfoComponent {
  @Input()
  wellRequest!: WellRequest | undefined;
}
