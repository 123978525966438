<mat-toolbar>
  <nav mat-tab-nav-bar [tabPanel]="tabPanel" >
    <div  *ngFor="let r of routes" >
      <a mat-tab-link routerLinkActive [active]="r.active"
         [routerLink]="r.route" *ngIf="showTab(r.roles)" [disabled]="r.disabled">
        <mat-icon class="menu-tab-icon">insert_drive_file</mat-icon>
        <span class="menu-tab-text">{{r.text}}</span>
      </a>
    </div>
  </nav>
  <mat-tab-nav-panel #tabPanel></mat-tab-nav-panel>

  <!--<span>title</span>-->
  <span class="menu-spacer"></span>
  <div *ngIf="isMenuVisible">
    <button mat-icon-button class="menu-toolbar-icon person-icon" aria-label="User Login" [matMenuTriggerFor]="loginMenu" *ngIf="!isUserloggedIn">
      <mat-icon>person</mat-icon>
    </button>
    <button mat-icon-button class="menu-toolbar-icon" aria-label="User Logout" [matMenuTriggerFor]="logoutMenu" *ngIf="isUserloggedIn">
      <mat-icon svgIcon="logout" aria-hidden="false"></mat-icon>
    </button>
    <!--<a mat-button [routerLink]="['/profile']">User Profile</a>-->
    <mat-menu #loginMenu="matMenu">
      <button mat-menu-item (click)="login()">Login</button>
      <!--<button mat-menu-item (click)="loginPopup()">Login using Popup</button>-->
    </mat-menu>
    <mat-menu #logoutMenu="matMenu">
      <button mat-menu-item (click)="logout()">Logout</button>
      <!--<button mat-menu-item (click)="logout(true)">Logout using Popup</button>-->
    </mat-menu>
  </div>
</mat-toolbar>
