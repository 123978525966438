import {  ActivatedRouteSnapshot, CanActivate, RouterStateSnapshot } from '@angular/router';
import { Injectable } from '@angular/core';
import { PermissionsService } from '../../services/permissions.service';
import { RoutingService } from '../../services/routing.service';
import { WellGuardBase } from './base-well-request.guard';

@Injectable({
  providedIn: 'root',
})
export class cliveGuard_CanAdminViewWellRequests extends WellGuardBase implements CanActivate {

  constructor(
     permissionsService: PermissionsService,
     routingService: RoutingService,
  ) {
    super(permissionsService, routingService );
  }

  async canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<boolean> {
    return this.isValidRedirect(route,'WellRequest_CanViewAdmin')
  }
}
