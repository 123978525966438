import { IUser } from "./user.model";
import { IWellRequestStatus } from "./well-request-status.model";

export class WellRequestLog {
  date: Date | undefined
  comments: string | undefined;
  userId: string | undefined;
  user: IUser | undefined;
  wellRequestId: number | undefined;
  wellRequestStatusId: number | undefined;
  wellRequestStatus: IWellRequestStatus | undefined;
  adminIds: string[] | undefined;
}
