import { IRegion } from "../../../model/region.model";
import { WellRequestComponent } from "../well-request.component";
import { WellRequestBaseHandler } from "./well-request-handler-base";

export class WellRequestRegionHandler extends WellRequestBaseHandler {
    private oldRegion: IRegion | undefined;

    constructor(wellRequestComponent: WellRequestComponent) {
        super(wellRequestComponent);
    }

    async regionHandler(regionData: any) {
      if (typeof regionData === 'string')
        await this.regionCleared(regionData);
      else
        await this.regionChanged(regionData);
    }

    private async regionChanged(region: IRegion) {
        if (this.wellRequestComp.countryControl.value.regionId === region.id) {
            if (!this.oldRegion)
                this.oldRegion = region;
            return;
        }

        if (this.wellRequestComp.selectedAdmins.length > 0) {
            const userConfirmed = await this.openDialogUpdate().toPromise();
            if (!userConfirmed) {
                this.wellRequestComp.regionControl.patchValue(this.oldRegion, { emitEvent: false });
                return;
            }
        }

        this.wellRequestComp.countryControl.reset('');
        this.getRigsByRegion(region.id ?? "1-");
        this.oldRegion = region;
    }

    private async regionCleared(region: string): Promise<void> {
        var isEmpty = region == '';

        if (isEmpty && this.wellRequestComp.selectedAdmins.length > 0) {
            const userConfirmed = await this.openDialogUpdate().toPromise();
            if (!userConfirmed) {
                this.wellRequestComp.regionControl.patchValue(this.oldRegion, { emitEvent: false });
                return;
            }
        }

        if (isEmpty) {
            this.wellRequestComp.countryControl.reset('');
            this.resetCountryAndFilters();
        }
        else
            this.clearFilters();
    }

    private resetCountryAndFilters() {
        this.wellRequestComp.countryControl.reset('');
        this.wellRequestComp.filteredCountries = this.wellRequestComp.countries;
        this.wellRequestComp.filteredGroups = this.wellRequestComp.groups;
        this.wellRequestComp.filteredRigs = this.wellRequestComp.rigs;
    }

    private clearFilters() {
        this.wellRequestComp.filteredCountries = [];
        this.wellRequestComp.filteredGroups = [];
    }
}
