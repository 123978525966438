<mat-label>{{ labelValue }}</mat-label><br />
<mat-form-field appearance="outline" subscriptSizing="dynamic">
  <input matInput
         type="text"
         [matAutocomplete]="auto"
         [placeholder]="placeholderValue"
         [(ngModel)]="value"
         [disabled]="disabled"
         app-autocomplete-validator
         required>
  <mat-autocomplete #auto="matAutocomplete"
                    [displayWith]="displayFn"
                    (opened)="onOpened()"
                    hideSingleSelectionIndicator="True">
    <cdk-virtual-scroll-viewport [ngStyle]="{height: '192px'}"
                                 itemSize="48"
                                 minBufferPx="200"
                                 maxBufferPx="400"
                                 #matAutocompleteViewport>
      <mat-option *cdkVirtualFor="let item of items" [value]="item">{{item.name}}</mat-option>
    </cdk-virtual-scroll-viewport>
  </mat-autocomplete>
</mat-form-field>
