import { Component, OnDestroy, OnInit } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ErrorHandlerService, IError } from '../services/error-handler.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-common-error-ui',
  styleUrls: ['./error-ui.component.css'],
  template: ''
})
export class ErrorUIComponent implements OnInit, OnDestroy {
  private _subscriptions: Subscription = new Subscription();

  constructor(
    public snackBar: MatSnackBar,
    readonly _errorHandlerService: ErrorHandlerService
  ) { }

  ngOnInit(): void {
    this._subscriptions.add(this._errorHandlerService.errorDetected$.subscribe(_ => this.newError(_)));
  }
  ngOnDestroy(): void {
    this._subscriptions.unsubscribe();
  }

  private newError(error: IError) {
    this.snackBar.open(error.message ?? "UNKNOWN MESSAGE", "Close", {
      duration: error.viewingTimeInSeconds * 1000,
      verticalPosition: 'bottom', // 'top' | 'bottom'
      horizontalPosition: 'end', //'start' | 'center' | 'end' | 'left' | 'right'
      panelClass: ['clive-snackbar-error']/*error.className,*/
    });
  }

  //private _i: number = 0;
  //dummyError(): void {
  //  this._i++;
  //  this._errorHandlerService.notify_ErrorDetails(`Error: ${this._i}`, "source Dummy")
  //}
}
