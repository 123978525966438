import { Component, OnInit } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { MsalService } from '@azure/msal-angular';
import { environment } from 'src/environments/environment';
import { BackendService } from '../services/backend.service';
import { IWellRequestStatus } from '../model/well-request-status.model';

export type MsProfileType = {
  givenName?: string,
  surname?: string,
  userPrincipalName?: string,
  id?: string
};

export type IdTokenClaims = {
  family_name?: string,
  given_name?: string,
  name?: string,
  preferred_username?: string
  samaccountname?: string
  upn?: string
};

@Component({
  selector: 'app-profile',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.css']
})
export class ProfileComponent implements OnInit {
  profile: MsProfileType | undefined;
  idTokenClaims: IdTokenClaims | undefined;
  wellRequestStatus: IWellRequestStatus[] | undefined;
  pong: string | undefined;
  currentApplicationVersion = environment.version.appVersion;
  currentBuildNumber = environment.version.buildNumber;
  currentEnvironmentSuffix = environment.version.suffix;

  constructor(
    private _http: HttpClient,
    private _msalService: MsalService,
    readonly _backendServiceService: BackendService
  ) { }

  ngOnInit() {
    this.getProfileFromMsGraph();
    this.getClaims();
    this.getPing();
    this.getWellRequestStatus();
  }
    
  private getProfileFromMsGraph(): void {
    this._http.get(environment.msGraphConfig.uri).subscribe(profile => this.profile = profile);
  }
  //This also has some information about the user without using MS Graph
  private getClaims(): void {
    this.idTokenClaims = this._msalService.instance.getActiveAccount()?.idTokenClaims;
  }

  private getPing(): void {
    this._backendServiceService.getPing().subscribe({
      error: (e) => {
        //console.error('API not available', e);
        //this.pong = JSON.stringify(e);
      },
      next: (_) => this.pong = _,
      //complete: () => console.log('getWeatherForecast complete!')
    });
  }

  private getWellRequestStatus(): void {
    this._backendServiceService.getWellRequestStatus().subscribe({
      //error: (e) => console.error('Custom error handler', e),
      next: (_) => this.wellRequestStatus = _,
      //complete: () => console.log('getWeatherForecast complete!')
    });
  }
}
