import { COMMA, ENTER } from '@angular/cdk/keycodes';
import {  Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild, forwardRef, inject } from '@angular/core';
import { FormControl, NG_VALUE_ACCESSOR, ControlValueAccessor, Validators } from '@angular/forms';
import { CdkVirtualScrollViewport } from '@angular/cdk/scrolling';
import { MatAutocomplete, MatAutocompleteSelectedEvent, MatAutocompleteTrigger } from '@angular/material/autocomplete';
import { LiveAnnouncer } from '@angular/cdk/a11y';
import { IBaseEntity } from '../../model/base-entity.model';

@Component({
  selector: 'app-chip',
  templateUrl: './chip.component.html',
  styleUrls: ['./chip.component.css'],
  providers: [{
    provide: NG_VALUE_ACCESSOR,
    useExisting: forwardRef(() => ChipComponent),
    multi: true
  }]
})
export class ChipComponent implements ControlValueAccessor, OnInit {
  @ViewChild(MatAutocomplete)
  autocomplete!: MatAutocomplete;

  @ViewChild(MatAutocompleteTrigger)
  autocompleteTrigger!: MatAutocompleteTrigger;

  @ViewChild("matAutocompleteViewport")
  virtualScrollViewport!: CdkVirtualScrollViewport;

  @ViewChild('chipInput') chipInput!: ElementRef<HTMLInputElement>;

  private _val: any;

  items!: IBaseEntity[];
  dataSourceItems!: IBaseEntity[];
  labelItem: string = "";
  placeholderItem: string = "";
  close: boolean | undefined;
  maxCount: number | undefined;
  separatorKeysCodes: number[] = [ENTER, COMMA];
  chipCtrl = new FormControl('', Validators.required);
  selectedItems: IBaseEntity[] = [];

  @Output() selectedItemsEvent = new EventEmitter<IBaseEntity[]>();
  @Output() removeEvent = new EventEmitter<{ beforeRemove: IBaseEntity[], afterRemove: IBaseEntity[] }>();

  @Input() closeEvent!: EventEmitter<boolean>;

  @Input() set dataSource(value: IBaseEntity[]) {
    let sortedItems = value.sort(this.sortByName());
    this.dataSourceItems = sortedItems;
    this.items = sortedItems.slice();

    if ( typeof this._val === 'string') {
      this.items = this.filter(this._val as string);
    }
  }

  @Input() set selectedSourceItems(value: IBaseEntity[]) {
    this.selectedItems = value.sort(this.sortByName());

    this.onChange(this.selectedItems);
    this.onTouch(this.selectedItems);
  }

  @Input() isRemovableFunc: (value: IBaseEntity) => boolean = (value: IBaseEntity) => true;

  @Input() set label(value: string) {
    this.labelItem = value;
  }

  @Input() set placeholder(value: string) {
    this.placeholderItem = value;
  }

  @Input() set count(value: number) {
    this.maxCount = value;
  }

  announcer = inject(LiveAnnouncer);

  ngOnInit() {
    this.closeEvent?.subscribe((result) => {
      this.close = result;
      this.autocompleteTrigger.closePanel();
    })
  }

  get value(): any {
    return this._val;
  }

  set value(val: any) {

    if (this._val !== val && !this.selectedItems.map(x => x.id).includes(val?.id)) {
      this._val = val;

      this.onChange(val);
      this.onTouch(val);
      const name = typeof val === 'string' ? val : undefined;
      this.items = name ? this.filter(name as string) : this.dataSourceItems.slice();
    }

    if (val == null)
      this.resetValidation();
  }

  displayFn(item: IBaseEntity): string {
    return item && item.name ? item.name : '';
  }

  remove(item: IBaseEntity): void {
    const index = this.selectedItems.indexOf(item);
    if (index >= 0) {
      let beforeRemove = [...this.selectedItems];
      this.selectedItems.splice(index, 1);
      this.onChange(this.selectedItems);
      this.onTouch(this.selectedItems);
      this.removeEvent.emit({ beforeRemove: beforeRemove, afterRemove: this.selectedItems });
      this.announcer.announce(`Removed ${item.name}`);
    }
    this.autocompleteTrigger.closePanel();
  }

  selected(event: MatAutocompleteSelectedEvent): void {
    const item = this.dataSourceItems.find(item => item.name === event.option.viewValue);
    if (this.maxCount && this.selectedItems.length == this.maxCount)
      return;

    if (item && !this.selectedItems.map(x => x.id).includes(item.id)) {
      this.selectedItems.push(item);
      this.selectedItems = this.selectedItems.sort(this.sortByName());
      this.onChange(this.selectedItems);
      this.onTouch(this.selectedItems);
      this.selectedItemsEvent.emit(this.selectedItems);
    }
   
    this.chipInput.nativeElement.value = '';
  }

  onOpened() {
    if (this.close) 
      this.autocompleteTrigger.closePanel();
    
    this.virtualScrollViewport.checkViewportSize();
    this.autocompleteTrigger._handleKeydown = (event) => {
      if (event.key === "Tab") 
        this.autocomplete._keyManager.onKeydown(event);
    };
  }

  writeValue(value: any): void {
      this.value = value;
  }

  registerOnChange(fn: any) {
    this.onChange = fn
  }

  registerOnTouched(fn: any) {
    this.onTouch = fn
  }

  invokeSelectedItems(value: IBaseEntity[]) {
    this.selectedItemsEvent.emit(value);
  }

  private onChange: any = () => { };

  private onTouch: any = () => { };

  private filter(value: string): IBaseEntity[] {
    const filterValue = value.toLowerCase();
    return this.dataSourceItems.filter(item => item && item.name && item.name.toLowerCase().includes(filterValue));
  }

  private resetValidation() {
    this.chipInput.nativeElement.value = '';
    if (this.chipCtrl.value != null)
      this.chipCtrl.reset();
    this.chipCtrl.markAsTouched();
    this.chipCtrl.markAsDirty();
    this.chipCtrl.updateValueAndValidity();
  }

  private sortByName(): ((a: IBaseEntity, b: IBaseEntity) => number) | undefined {
    return (a, b) => (a.name ?? '') < (b.name ?? '') ? -1 : 1;
  }

  setDisabledState(isDisabled: boolean): void {
    if (isDisabled)
      this.chipCtrl.disable();
    else {
      this.chipCtrl.enable();
      this.chipCtrl.markAsUntouched();
      this.chipCtrl.updateValueAndValidity();
    }
  }
}
