<app-back-button></app-back-button>
<span class="history-log-header"><strong>HISTORY LOG |</strong><span>&nbsp;{{ wellRequest?.name }}</span></span>
<div class="history-log-status">
  <app-status-icon [status]="preselectedStatus"></app-status-icon>
</div>
<mat-divider></mat-divider>

<div class="mat-elevation-z8 history-log-table">
  <table mat-table [dataSource]="dataSource" matSort aria-label="Elements">
    <ng-container matColumnDef="by">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>PERFORMED BY</th>
      <td mat-cell *matCellDef="let row">{{ row.by }}</td>
    </ng-container>

    <ng-container matColumnDef="status">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>STATUS</th>
      <td mat-cell *matCellDef="let row">{{ row.status }}</td>
    </ng-container>

    <ng-container matColumnDef="date">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>DATE</th>
      <td mat-cell *matCellDef="let row">{{ row.date + "Z" | date:'yyyy-MM-dd HH:mm:ss' }}</td>
    </ng-container>

    <ng-container matColumnDef="adminIds">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>ADMIN</th>
      <td mat-cell *matCellDef="let row">{{ row.adminIds }}</td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns;" class="row-hover"></tr>
  </table>

  <mat-paginator [pageSizeOptions]="[5, 10, 20]" aria-label="Select page"></mat-paginator>
</div>
