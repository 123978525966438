import { Component, OnInit } from '@angular/core';
import { ParamMap } from '@angular/router';
import { BackendService } from '../../services/backend.service';
import { WellRequest } from '../../model/well-request.model';
import { Observable, lastValueFrom } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import { PermissionsService } from 'src/app/services/permissions.service';
import 'src/app/extensions/admin-array-extension'
import { IStatus } from '../../model/status.model';
import { WellReuqestBase } from '../well-base/well-request-base';
import { MatSnackBar } from '@angular/material/snack-bar';
import { AbstractControl, FormBuilder, Validators } from '@angular/forms';
import { WellRequestStatus_Options } from '../../model/status-enum.model';
import { RoutingService } from '../../services/routing.service';
import { EmailService } from '../../services/email.service';
import { IAdmin } from '../../model/admin.model';
import { MsalService } from '@azure/msal-angular';
import { IdTokenClaims } from '../../profile/profile.component';
import { WellRequestLog } from '../../model/well-request-log.model';
import { WellRequestAdmin } from '../../model/well-request-admin.model';

@Component({
  selector: 'app-well-details',
  templateUrl: './well-details.component.html',
  styleUrls: ['./well-details.component.css'],
  providers: [RoutingService]
})
export class WellDetailsComponent extends WellReuqestBase implements OnInit {
  private get adminControl(): AbstractControl<any, any> { return this.form.controls['admin'] };

  wellRequest$!: Observable<WellRequest>;
  predefinedStatus: IStatus | undefined;
  wellRequestStatus: WellRequestStatus_Options | undefined;
  wellRequest: WellRequest | undefined;
  isCanEdit: boolean = false;
  isDisableSubmit: boolean = true;

  filteredAdmins: IAdmin[] = [];
  selectedAdmins: IAdmin[] = [];
  initialAdmins: IAdmin[] = [];

  constructor(
    _routingService: RoutingService,
    _snackBar: MatSnackBar,
    _fb: FormBuilder,
    readonly _msalService: MsalService,
    _backendService: BackendService,
    protected _permissionService: PermissionsService,
    _email: EmailService) {
    super(_routingService, _snackBar, _fb, _backendService, _email);
    this.form = this.fb.group({
      admin: ['', Validators.required]
    });
  }

  ngOnInit(): void {
    this.loadWells();
  }

  loadWells(): void {
    this.wellRequest$ = this.routingService.activatedRoute.paramMap.pipe(
      switchMap((params: ParamMap) =>
        this.backendService.getWellRequest(params.get('id') ?? ""))
    );

    this.wellRequest$.subscribe((data) => {
      this.wellRequest = data;
      if (this.wellRequest.id)
        this.backendService.isOwnerWellRequest(this.wellRequest.id.toString())
          .subscribe((isOwner) => {
            if (!isOwner)
              return;

            if (this.checkisEditable())
              this.setAdmins()
          })
    });
  }

  submitHandler(event: any) {
    let isSubmited = event as boolean;
    if (isSubmited)
      this.submitPopup();
  }

  canCancel(): boolean {
    return this._permissionService.HasWellRequestPermissions("WellRequest_CanCancel");
  }

  adminRemoveChanged(event: any) {
    let afterRemoveAdmins = event.afterRemove as IAdmin[];

    this.isDisableSubmit = afterRemoveAdmins.equalAdmins(this.initialAdmins)
                            || afterRemoveAdmins.length == 0;
  }

  adminSelectionChanged(event: any) {
    let admins = event as IAdmin[];

    this.isDisableSubmit = admins.equalAdmins(this.initialAdmins);
  }

  private submitPopup() {
    let claims: IdTokenClaims | undefined = this._msalService.instance.getActiveAccount()?.idTokenClaims;
    if (claims && claims.samaccountname) {
      if (!this.wellRequest?.groups
        || this.selectedAdmins.equalAdmins(this.initialAdmins)) 
        return;

      let adminIds = this.selectedAdmins
                                .getWellRequestAdmins(this.wellRequest?.groups)
                                .map(ag => ag.adminId) as string[];

      this.backendService.updateAdmins(adminIds,
        this.wellRequest.id?.toString() ?? "-1")
        .toPromise()
        .then(_ => this.handelLogRequest(
          (log: WellRequestLog) => this.backendService.logDuplicate(log),
          (log: WellRequestLog) => {
            this.sendEmailForNewAdmins(log,
              this.selectedAdmins.exceptAdmins(this.initialAdmins));
            this.initialAdmins = this.selectedAdmins.slice();
            this.isDisableSubmit = true;
          })
        );
    }
    else
      this._msalService.loginRedirect();
  }

  private sendEmailForNewAdmins(log: WellRequestLog | undefined, hallIds: string[]) {
    if (log?.wellRequestId && hallIds.length > 0)
      this.email.EmailNotify(BigInt(log?.wellRequestId), true, hallIds)
  }

  private checkisEditable(): boolean {
    if (this.wellRequest?.currentStatusId) {
      this.isCanEdit = [WellRequestStatus_Options.Created, WellRequestStatus_Options.Resubmited]
        .includes(this.wellRequest?.currentStatusId);

    }
    return this.isCanEdit;
  }

  private async setAdmins(): Promise<void> {
    if (this.wellRequest?.groups) {
      const ids = this.wellRequest.groups.map(x => x.id).filter(x => x);
      
      this.filteredAdmins = await lastValueFrom(this.backendService.getAdminsByGroups(ids as string[]));
      this.adminControl.updateValueAndValidity({ onlySelf: true, emitEvent: false });

      this.preselectedAdmins(this.wellRequest.admins);
    }
  }

  private preselectedAdmins(adminsId: WellRequestAdmin[] | undefined) {
    if (adminsId) {
      let adminSelected = this.filteredAdmins.getAdmins(adminsId);;
      this.adminControl.patchValue(adminSelected, { emitEvent: false });
      this.selectedAdmins = adminSelected;
      this.initialAdmins = this.selectedAdmins.slice();
    }
  }
}
