<div class="main-container">
  <ng-template #emptyField><span>N/A</span></ng-template>

  <div class="well-info-item">
    <mat-label>RTS Environment</mat-label>
    <span>{{ wellRequest?.rtsName }}</span>
  </div>

  <div class="well-info-item">
    <mat-label>Region</mat-label>
    <span>{{ wellRequest?.region }}</span>
  </div>

  <div class="well-info-item">
    <mat-label>Country</mat-label>
    <span>{{ wellRequest?.country }}</span>
  </div>

  <div class="well-info-item">
    <mat-label>Group Name</mat-label>
    <ng-container *ngIf="wellRequest?.groups?.length">
      <span>
        <ng-container *ngFor="let group of wellRequest?.groups; let last = last">
          {{ group.name }}<span style="padding:0px; margin:0px" *ngIf="!last">, </span>
        </ng-container>
      </span>
    </ng-container>
  </div>

  <div class="well-info-item">
    <mat-label>NWA</mat-label>
    <ng-container *ngIf="wellRequest?.groups?.length">
      <span>
        <ng-container *ngFor="let group of wellRequest?.groups; let last = last">
          {{ group.nwa }}<span style="padding:0px; margin:0px" *ngIf="!last">, </span>
        </ng-container>
      </span>
    </ng-container>
  </div>

  <div class="well-info-item">
    <mat-label>State</mat-label>
    <span *ngIf="wellRequest?.state;else emptyField">{{ wellRequest?.state }}</span>
  </div>

  <div class="well-info-item">
    <mat-label>District</mat-label>
    <span *ngIf="wellRequest?.district;else emptyField">{{ wellRequest?.district }}</span>
  </div>

  <div class="well-info-item">
    <mat-label>Operator</mat-label>
    <span>{{ wellRequest?.operatorName }}</span>
  </div>

  <div class="well-info-item">
    <mat-label>Rig</mat-label>
    <span>{{ wellRequest?.rigName }}</span>
  </div>

  <mat-grid-list class="coordinates-list" cols="2" rowHeight="60px" gutterSize="60px">
    <mat-grid-tile>
      <div class="well-info-item coordinates-item">
        <mat-label>Latitude</mat-label>
        <span>{{ wellRequest?.latitude }}</span>
      </div>
    </mat-grid-tile>

    <mat-grid-tile>
      <div class="well-info-item coordinates-item">
        <mat-label>Longitude</mat-label>
        <span>{{ wellRequest?.longitude }}</span>
      </div>
    </mat-grid-tile>
  </mat-grid-list>
</div>
