import { Component, EventEmitter, OnInit } from '@angular/core';
import { AbstractControl, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MsalService } from '@azure/msal-angular';
import { AuthenticationResult } from '@azure/msal-browser';
import { lastValueFrom } from 'rxjs';
import 'src/app/extensions/admin-array-extension'
import { IBaseEntity } from 'src/app/model/base-entity.model';
import { ICountry } from 'src/app/model/country.model';
import { IOperator } from 'src/app/model/operator.model';
import { PopupData } from 'src/app/model/popup.model';
import { IRegion } from 'src/app/model/region.model';
import { IRig } from 'src/app/model/rig.model';
import { IWellEnvironment } from 'src/app/model/well-environment.model';
import { WellRequest } from 'src/app/model/well-request.model';
import { IdTokenClaims } from 'src/app/profile/profile.component';
import { BackendService } from 'src/app/services/backend.service';
import { environment } from '../../../environments/environment.dev';
import { WellRequestStatus_Options } from '../../model/status-enum.model';
import { RoutingService } from '../../services/routing.service';
import { EmailService } from '../../services/email.service';
import { IGroup } from '../../model/group.model';
import { IAdmin } from '../../model/admin.model';
import { WellRequestAdmin } from '../../model/well-request-admin.model';
import { WellRequestCountryHandler } from './well-request-handler/well-request-handler-country';
import { WellRequestRegionHandler } from './well-request-handler/well-request-handler-region';
import { WellRequestGroupHandler } from './well-request-handler/well-request-handler-group';

@Component({
  selector: 'app-well-request',
  templateUrl: './well-request.component.html',
  styleUrls: ['./well-request.component.css'],
  providers: [RoutingService]
})
export class WellRequestComponent implements OnInit {
  private _emailToken: AuthenticationResult | undefined
  public get regionControl(): AbstractControl<any, any> {return this.form.controls['region']};
  public get countryControl(): AbstractControl<any, any> { return this.form.controls['country'] };
  public get operatorControl(): AbstractControl<any, any> { return this.form.controls['operator'] };
  public get rigControl(): AbstractControl<any, any> { return this.form.controls['rig'] };
  public get groupControl(): AbstractControl<any, any> { return this.form.controls['group'] };
  public get adminControl(): AbstractControl<any, any> { return this.form.controls['admin'] };

  regionDataForDropdown: any = [];

  popupSubmitData: PopupData | undefined;
  popupUpdateData: PopupData | undefined;
  form: FormGroup;
  editForm: boolean = false;
  header: string | undefined;

  wellEnvironments: IWellEnvironment[] = [];
  regions: IRegion[] = [];
  countries: ICountry[] = [];
  filteredCountries: ICountry[] = [];
  groups: IGroup[] = [];
  filteredGroups: IGroup[] = [];
  selectedGroups: IGroup[] = [];
  admins: IAdmin[] = [];
  filteredAdmins: IAdmin[] = [];
  selectedAdmins: IAdmin[] = [];
  operators: IOperator[] = [];
  rigs: IRig[] = [];
  filteredRigs: IRig[] = [];
  wellRequest: WellRequest | undefined;

  initialAdmins: IAdmin[] = [];
  previousCountryId: string | undefined;
  oldRegion: IBaseEntity | undefined;
  oldCountry: ICountry | undefined;
  countryHandler!: WellRequestCountryHandler;
  regionHandler!: WellRequestRegionHandler;
  groupHandler!: WellRequestGroupHandler;
  closeEvent = new EventEmitter<boolean>();

  constructor(
    readonly _routingService: RoutingService,
    readonly _formBuilder: FormBuilder,
    readonly _backendService: BackendService,
    readonly _msalService: MsalService,
    readonly _dialog: MatDialog,
    readonly _emailService: EmailService,
    readonly _snackBar: MatSnackBar) {
    this.form = this.getFormGroup();
    this.initSubmitPopup();
  }

  private getFormGroup(): FormGroup {
    let coordinatePattern = new RegExp("^-?\\d+\\.?");
    return this._formBuilder.group({
      name: ['', Validators.compose([Validators.required, Validators.maxLength(50)])],
      wellEnvironment: ['', Validators.required],
      region:  [{value: '', disabled: true}, Validators.required],
      country: [{value: '', disabled: true}, Validators.required],
      group: [{value: '', disabled: true}, Validators.required],
      admin: [{value: '', disabled: true}, Validators.required],
      state: ['', Validators.maxLength(50)],
      district: ['', Validators.maxLength(50)],
      operator: [{value: '', disabled: true}],
      rig: [{value: '', disabled: true}, Validators.required],
      latitude: ['', Validators.compose([Validators.required, Validators.min(-90), Validators.max(90), Validators.pattern(coordinatePattern)])],
      longitude: ['', Validators.compose([Validators.required, Validators.min(-180), Validators.max(180), Validators.pattern(coordinatePattern)])]
    });
  }

  ngOnInit() {
    this.initHandlers();
    this.subscribeToEvents();
    this.preselectFromRoute();
    this.setWellEnvironments();
    let wellRequestId: number = this._routingService.getWellRequestId();
    this.editForm = wellRequestId !== -1;
    this.setRegions()
      .then(() => this.setCountries()
      .then(() => this.setGroups())
      .then(() => this.setAdmins())
      .then(() => this.setOperators())
      .then(() => this.setRigs())
      .then(() => this.loadWellsRequest(wellRequestId)));
    this.setEmailToken();
    this.initUiData(wellRequestId !== -1);
  }

  getAdminsByGroups(groups: IGroup[] | undefined) {
    if (!groups || groups.length == 0) {
      this.resetAdmins(groups);
      return;
    }

    this.filteredAdmins = this.admins.filter(x => x.adminGroups
      && x.adminGroups.some(ids => groups.map(id => id.id)
        .includes(ids.groupId)))
      .reduce((acc: IAdmin[], curr) => {
        if (!acc.map(a => a.id).includes(curr.id)) {
          acc.push(curr);
        }
        return acc;
      }, []);

    this.selectedAdmins = this.selectedAdmins
      .filter(x => x.adminGroups?.find(a => groups.some(g => g.id == a.groupId)));
  }

  groupsSelectionChanged(event: any) {
    let groups = event as IGroup[];
    this.groupHandler.groupSelectedHandler(groups);
  }

  async groupsRemoveChanged(event: any) {
    let beforeRemove = event.beforeRemove as IGroup[];
    await this.groupHandler.groupRemoveHandler(beforeRemove);
  }

  getFilteredCountries<T>(regionId: T | undefined): ICountry[] {
    return this.countries.filter(country => country.regionId == regionId);
  }

  patchCountry(countryId: string): ICountry | undefined {
    const country = this.countries.find(x => x.id == countryId)
    this.countryControl.setValue(country, { emitEvent: false, });
    return country;
  }

  submitHandler(event: any) {
    let isSubmited = event as boolean;
    if (isSubmited)
      this.submitPopup();
  }

  isAdminRemovable = (event: any) => {
    let admin = event as IAdmin;
    return !admin.isTookAction
  }

  private initHandlers() {
    this.regionHandler = new WellRequestRegionHandler(this);
    this.countryHandler = new WellRequestCountryHandler(this);
    this.groupHandler = new WellRequestGroupHandler(this);
  }

  private subscribeToEvents() {
    this.regionControl.valueChanges.subscribe((regionData: any) => {
      this.regionHandler.regionHandler(regionData);
    });

    this.countryControl.valueChanges.subscribe((country: any) => {
      this.countryHandler.countryHandler(country);
   });

    this.groupControl.valueChanges.subscribe((groupData: any) => {
      this.groupHandler.groupChangeHandler(groupData);
    });

    this.rigControl.valueChanges.subscribe((rigData: any) => {
      this.rigHandler(rigData);
    });
  }

  private rigHandler(rigData: any) {
    if (rigData?.id && this.groupControl.value?.length == 0) {
      let rigGroup = this.groups.find(x => x.id == rigData?.groupId);
      if (!rigGroup)
        return;

      this.groupControl.patchValue(rigGroup, { emitEvent: false });
      this.selectedGroups = [rigGroup]
      this.filteredRigs = this.rigs.filter(x => x.groupId == rigGroup?.id);
    }
  }

  private loadWellsRequest(wellRequestId: number): void {
    if (wellRequestId === -1)
      return;

    this._backendService.getWellRequest(wellRequestId.toString()).subscribe(
      data => {
        this.wellRequest = data;
        let operatorId: string = data.operatorId ?? "";
        let rigId: string = data.rigId ?? "";
        let regionId: string = data.regionId?.toString() ?? "";
        let countryId: string = data.countryId?.toString() ?? "";
        this.preseletOperator(operatorId);
        this.preselectRegion(regionId);
        this.preselectCountry(countryId);
        this.preselectedGroups(this.wellRequest.groups);
        this.preselectedAdmins(this.wellRequest.admins);
        this.preselectRig(rigId);
        this.form.controls['name'].setValue(data.name);
        this.form.controls['wellEnvironment'].setValue(data.rtsEnvironmentId);
        this.form.controls['state'].setValue(data.state);
        this.form.controls['district'].setValue(data.district);
        this.form.controls['longitude'].setValue(data.longitude);
        this.form.controls['latitude'].setValue(data.latitude);
        this.regionControl.enable();
        this.countryControl.enable();
        this.groupControl.enable();
        this.operatorControl.enable();
        this.rigControl.enable();
        this.adminControl.enable();
      }
    );
  }

  private initUiData(isEdit: boolean) {
    if (!isEdit)
      this.header = "New Well Request";
  }

  private preselectFromRoute(): void {
    this._routingService.activatedRoute.queryParams.subscribe(params => {
      let operatorId: string = params['operatorId'];
      let rigId: string = params['rigId'];
      this.preseletOperator(operatorId);
      this.preselectRig(rigId);
    });
  }

  private setWellEnvironments(): void {
    this._backendService.getWellEnvironment().subscribe((wellEnvironments : IWellEnvironment[]) => {
      this.wellEnvironments = wellEnvironments;
      this.setWellEnvironmentDefault();
    });
  }

  private setWellEnvironmentDefault(): void {
    if (this.wellEnvironments.length == 1) {
      let wellEnvironmentField = this.form.controls['wellEnvironment'];
      wellEnvironmentField.patchValue(this.wellEnvironments[0].id);
      wellEnvironmentField?.disable();
    }
  }

  private preseletOperator(operatorId: string): void {
    if (operatorId) {
      this.getOperator(operatorId, (operator: IOperator) => {
        this.form.controls['operator'].patchValue(operator);
      });
    }
  }

  private getOperator(id: string, callback: Function): void {
    let operator: IOperator | undefined = this.operators.find(operator => operator.id === id);
    if (!operator) {
      this._backendService.getOperator(id).subscribe((operator : IOperator) => {
        if (!this.operators.some(x => x.id === operator.id))
          this.operators.push(operator);
        callback(operator);
      });
    }
    else {
      callback(operator);
    }
  }

  private preselectRig(rigId: string): void {
    if (rigId) {
      rigId = rigId?.toLowerCase();
      this.getRig(rigId, (rig: IRig) => {
        this.form.controls['rig'].patchValue(rig);
      });
    }
  }

  private getRig(id: string, callback: Function): void {
    let rig: IRig | undefined = this.rigs.find(rig => rig.id === id);
    if (!rig) {
      this._backendService.getRig(id).subscribe((rig : IRig) => {
        if (!this.rigs.some(x => x.id === rig.id)) {
          this.rigs.push(rig);
          this.filteredRigs.push(rig)
        }
        callback(rig);
      });
    }
    else {
      callback(rig);
    }
  }

  private preselectRegion(regionId: string): void {
    if (regionId) {
      this.get(regionId, this.regions, this._backendService.getRegion, (region: IRegion) => {
        this.regionControl.patchValue(region.id, {emitEvent: false});
        this.filteredCountries = this.getFilteredCountries(region.id);
      });
    }
  }

  private preselectCountry(countryId: string): void {
    if (countryId) {
      this.get(countryId, this.countries, this._backendService.getCountry, (country: ICountry) => {
        this.countryControl.patchValue(country, {emitEvent: false});
      });
    }
  }

  private preselectedGroups(groups: IGroup[] | undefined) {
    if (groups) {
      this.groupControl.patchValue(groups, { emitEvent: false });
      this.selectedGroups = groups;
      this.groupHandler.getRigsByGroups(groups);
      this.getAdminsByGroups(groups);
    }
  }

  private preselectedAdmins(adminsId: WellRequestAdmin[] | undefined) {
    if (adminsId) {
      let adminSelected = this.admins.getAdmins(adminsId);
      this.adminControl.patchValue(adminSelected, { emitEvent: false });
      this.selectedAdmins = adminSelected;
      this.initialAdmins = this.selectedAdmins.slice();
    }
  }


  private get(id: string, arr: IBaseEntity[], action: Function, callback: Function): void {
    let value: IBaseEntity | undefined = arr.find(item => item.id == id);
    if (!value) {
      action(id).subscribe((value: IBaseEntity) => {
        if (!arr.some(x => x.id === value.id))
          arr.push(value);

        callback(value);
      });
    }
    else {
      callback(value);
    }
  }

  private async setRegions(): Promise<void> {
    this.regions = await lastValueFrom(this._backendService.getRegions());
    this.regionControl.updateValueAndValidity({onlySelf: true, emitEvent: false});
    this.regionDataForDropdown=[];
    this.regions.forEach(item=>{
      this.regionDataForDropdown.push({id:item.id,name:item.code})
    })
    if (!this.editForm)
      this.regionControl.enable();
  }

  private async setCountries(): Promise<void>  {
    this.countries = this.filteredCountries = await lastValueFrom(this._backendService.getCountries());
    if (!this.editForm)
      this.countryControl.enable();
    this.countryControl.updateValueAndValidity({onlySelf: true, emitEvent: false});
  }

  private async setGroups(): Promise<void> {
    this.groups = this.filteredGroups = await lastValueFrom(this._backendService.getGroups());
    if (!this.editForm)
      this.groupControl.enable();
    this.groupControl.updateValueAndValidity({ onlySelf: true, emitEvent: false });
  }

  private async setAdmins(): Promise<void> {
    this.admins = await lastValueFrom(this._backendService.getAdmins());
    if (!this.editForm)
      this.adminControl.enable();
    this.adminControl.updateValueAndValidity({ onlySelf: true, emitEvent: false });
  }

  private async setOperators(): Promise<void> {
    this.operators = await lastValueFrom(this._backendService.getOperators());
    if (!this.editForm)
      this.operatorControl.enable();
    this.operatorControl.updateValueAndValidity({onlySelf: true, emitEvent: true});
  }

  private async setRigs(): Promise<void> {
    this.rigs = this.filteredRigs = await lastValueFrom(this._backendService.getRigs());
    if (!this.editForm)
      this.rigControl.enable();
    this.rigControl.updateValueAndValidity({onlySelf: true, emitEvent: true});
  }

  private setEmailToken(): void {
    const accessTokenRequest = {
      scopes: environment.msGraphConfig.scopes
    };
    this._msalService.acquireTokenSilent(accessTokenRequest).subscribe({
      next: (response: AuthenticationResult) => {
        this._emailToken = response;
      }
    });
  }

  private submitPopup() {
    let claims: IdTokenClaims | undefined = this._msalService.instance.getActiveAccount()?.idTokenClaims;
    if (claims && claims.samaccountname) {
      let wellRequest: WellRequest = this.getWellRequest();
      wellRequest.userId = claims.samaccountname;
      if (this.editForm) {
        wellRequest.wellRequestStatusId = WellRequestStatus_Options.Resubmited
        this._backendService.updateWellRequest(wellRequest).subscribe({
          next: () => this.postWellRequestStep(wellRequest.id),
          error: (error) => {
            if (error.status == 409)
              this.wellNameValidation(error.error);
          }
        });
      }
      else
        this._backendService.createWellRequest(wellRequest).subscribe({
          next: (wellRequestId: bigint) => this.postWellRequestStep(wellRequestId),
          error: (error) => {
            if (error.status == 409)
              this.wellNameValidation(error.error);
          }
        });
    }
    else {
      this._msalService.loginRedirect();
    }  
  }

  private postWellRequestStep(wellRequestId: bigint | undefined) {
    if (wellRequestId) {
      this._emailService.EmailNotify(wellRequestId, true)
      let newAdmins = this.selectedAdmins.exceptAdmins(this.initialAdmins);

      if (newAdmins.length > 0 && this.initialAdmins.length > 0)
        this._emailService.EmailNotify(BigInt(wellRequestId),
                                       true,
                                       newAdmins);
      this._routingService.forwardRedirect(wellRequestId.toString())
    }
  }

  get uniqueNWA() {
    const unique = [];
    const map = new Map();
    for (const group of this.selectedGroups) {
      if (!map.has(group.nwa)) {
        map.set(group.nwa, true);
        unique.push(group);
      }
    }
    return unique;
  }

  private async resetAdmins(groups: IGroup[] | undefined) {
    if (!groups || groups?.length == 0) {
      this.adminControl.reset();
      this.selectedAdmins = [];
      this.filteredAdmins = []
      return;
    }
  }

  private getWellRequest(): WellRequest {
    let value: any = this.form.getRawValue();
    let wellRequest = new WellRequest();
    if (this.editForm) {
      wellRequest.id = this.wellRequest?.id
    }
    wellRequest.name = value.name;
    wellRequest.rtsEnvironmentId = value.wellEnvironment;
    wellRequest.countryId = value.country.id;
    wellRequest.regionId = value.region.id;
    wellRequest.state = value.state;
    wellRequest.district = value.district;
    wellRequest.operatorId = value.operator.id;
    wellRequest.rigId = value.rig.id;
    wellRequest.latitude = value.latitude;
    wellRequest.longitude = value.longitude;
    wellRequest.groups = value.group;
    if (wellRequest.groups)
      wellRequest.admins = (value.admin as IAdmin[])
                              .getWellRequestAdmins(wellRequest.groups);
    return wellRequest;
  }

  private wellNameValidation(message: string) {
    
    this._snackBar.open(message, "Close ✕", {
      duration: 10000,
      verticalPosition: 'bottom',
      horizontalPosition: 'end',  
      panelClass: "well-name-snackbar"
    });
    this.setNotValid(this.form.controls['name'])
  }

  private setNotValid(control: AbstractControl<any>) {
     control.setErrors({ "invalid": true });
     control.markAllAsTouched();
  }

  private initSubmitPopup() {
    this.popupSubmitData = new PopupData();
    this.popupSubmitData.header = "Submit Well Request";
    this.popupSubmitData.textInfo = `
      <span>
        Please note that after clicking <strong>SUBMIT REQUEST,<br/>
        additional changes</strong> to the request <strong>will not be possible</strong><br/>
        until it is <strong>reviewed by the administrators.</strong>
      </span>
    `;
    this.popupSubmitData.textSubmit = "YES, SUBMIT REQUEST"
  }
}
