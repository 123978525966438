import { Component, AfterViewInit, ViewChild } from '@angular/core';
import { WellRequestTableComponent } from './well-request-table.component';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MsalService } from '@azure/msal-angular';
import { BackendService } from '../../services/backend.service';
import { Router } from '@angular/router';
import { IdTokenClaims } from 'src/app/profile/profile.component'
import { RoutingService } from '../../services/routing.service';
import { PermissionsService } from '../../services/permissions.service';

@Component({
  selector: 'app-well-request-table-all',
  templateUrl: './well-request-table.component.html',
  styleUrls: ['./well-request-table.component.css'],
  providers: [PermissionsService]
})
export class WellRequestTablePendingComponent extends WellRequestTableComponent implements AfterViewInit {
  @ViewChild(MatPaginator) set setPaginator(value : MatPaginator ) {
    this.paginator = value;
  };
  @ViewChild(MatSort) set setSort(value: MatSort) {
    this.sort = value;
  }

  displayedColumns = ['id', 'wellName', 'userName', 'date', 'status'];
  header: string = 'Pending Requests';
  idTokenClaims: IdTokenClaims | undefined;

  constructor(private _msalService: MsalService, routingService: RoutingService, backendService: BackendService) {
    super(backendService, routingService);
  }

  override ngOnInit(): void {
    this.getClaims();
    super.ngOnInit();
  }

  ngAfterViewInit() {
    this.intiPaginator();
  }

  private getClaims(): void {
    this.idTokenClaims = this._msalService.instance.getActiveAccount()?.idTokenClaims;
  }

  override loadWells(): void {
    this.backendService.getPendingWellRequestTable(this.idTokenClaims?.samaccountname).subscribe(data => {
      this.dataSource.data = data;
    })
  }
}
