import { Component, OnInit } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { WellRequestLog } from 'src/app/model/well-request-log.model';
import { BackendService } from 'src/app/services/backend.service';
import { PermissionsService } from 'src/app/services/permissions.service';
import { WellRequestStatus_Options } from '../../model/status-enum.model';
import { RoutingService } from '../../services/routing.service';
import { StatusHelperService } from '../../services/status-helper.service';
import { WellDetailsComponent } from '../well-details/well-details.component';
import { EmailService } from '../../services/email.service';
import { MsalService } from '@azure/msal-angular';

@Component({
  selector: 'app-well-admin-details',
  templateUrl: './well-admin-details.component.html',
  styleUrls: ['./well-admin-details.component.css'],
  providers: [RoutingService]
})
export class WellAdminsDetailsComponent extends WellDetailsComponent implements OnInit {
  private _currentStatus: WellRequestStatus_Options | undefined;
  _isSaveDisable: boolean = true;

  constructor(
    _backendService: BackendService,
    _permissionService: PermissionsService,
    _routingSevice: RoutingService,
    _snackBar: MatSnackBar,
     _msalService: MsalService,
    _formBuilder: FormBuilder,
    _email: EmailService,
    private readonly _statusHelper: StatusHelperService) {
    super(_routingSevice, _snackBar, _formBuilder, _msalService ,_backendService, _permissionService, _email);
    this.form = this.fb.group({
      comments: ['', Validators.compose([Validators.maxLength(250)])]
    });

    this.statusChanges();
  }

  requestChangesSubmit(): void {
    if (!this.validate(this.form.controls["comments"]))
      return;

    this.handelLogRequest((log: WellRequestLog) => this.backendService.requestChanges(log),
      (log: WellRequestLog) => this.sendEmail(log));
  }

  saveChangesSubmit(): void {
    if (this._currentStatus && this.wellRequest?.id)
    this.createPendingRequest(this._currentStatus, this.wellRequest.id)
  }

  onCommentsEnter(event: Event) {
    event.preventDefault();
  }

  private statusChanges() {
    this._statusHelper.statusItem$.subscribe((data) => {
      this._currentStatus = data;

      if (!this.wellRequest || data == this.wellRequest?.currentStatusId) {
        this._isSaveDisable = true;
      } else
        this._isSaveDisable = false;
    });
  }
}
