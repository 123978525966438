import { Component, EventEmitter, Input, Output } from '@angular/core';
import { SubmitPopupComponent } from '../../popup/submit/submit-popup.component';
import { MatDialog } from '@angular/material/dialog';
import { BackendService } from 'src/app/services/backend.service';
import { Location } from '@angular/common';
import { PopupData } from 'src/app/model/popup.model';
import { RoutingService } from '../../../services/routing.service';
import { EmailService } from '../../../services/email.service';
import { FormGroup } from '@angular/forms';

@Component({
  selector: 'app-submit-cancel-button',
  templateUrl: './submit-cancel-button.component.html',
  styleUrls: ['./submit-cancel-button.component.css'],
  providers: [RoutingService]
})
export class SubmitCancelButtonComponent {
  @Output() submitedEvent = new EventEmitter<boolean>();

  @Input()
  form!: FormGroup;

  @Input()
  popupSubmitData: PopupData | undefined;

  @Input()
  disableSubmit!: boolean;

  constructor(
    private readonly _dialog: MatDialog,
    private readonly _routingService: RoutingService) {  }

  back(): void {
    this._routingService.back();
  }

  openDialogSubmit() {
    if (this.form?.invalid)
      return;

    if (!this.popupSubmitData) {
      this.submitedEvent.emit(true);
      return;
    }

    const dialogRef = this._dialog.open(SubmitPopupComponent, { data: { popupData: this.popupSubmitData } });

    dialogRef.afterClosed().subscribe(result => {
      if (result)
        this.submitedEvent.emit(true);
    });
  }
}
