<h1>v{{currentApplicationVersion}}.{{currentBuildNumber}}{{currentEnvironmentSuffix}}</h1>

<h2>__User info from MS Graph __________________________________________</h2>
<div>
  <p><strong>First Name: </strong> {{profile?.givenName}}</p>
  <p><strong>Last Name: </strong> {{profile?.surname}}</p>
  <p><strong>Email: </strong> {{profile?.userPrincipalName}}</p>
  <p><strong>Id: </strong> {{profile?.id}}</p>
</div>
<br />

<h2>__idToken Claims __________________________________________</h2>
<!--<div>
  <div *ngFor='let rol of roles'>
    <p>
      <strong>Rol: </strong> { {rol}}
    </p>
  </div>
</div>-->
<div>
  <p><strong>First Name: </strong> {{idTokenClaims?.family_name}}</p>
  <p><strong>Last Name: </strong> {{idTokenClaims?.given_name}}</p>
  <p><strong>Full Name: </strong> {{idTokenClaims?.name}}</p>
  <p><strong>Email: </strong> {{idTokenClaims?.preferred_username}} / {{idTokenClaims?.upn}}</p>
  <p><strong>SamAccountName: </strong> {{idTokenClaims?.samaccountname}}</p>
</div>
<br />

<h2>__API "Ping" test________________________________________</h2>
<div>
  <p>
    <strong>Ping: </strong> {{pong}}
  </p>
</div>

<br />

<h2>__API "DB (Well Requests status)" test________________________________________</h2>
<div>
  <div *ngFor='let item of wellRequestStatus'>
    <p>
      <strong>id: </strong> {{item.id}}
      <strong>name: </strong> {{item.name}}
      <strong>displayedAs: </strong> {{item.displayedAs}}
      <strong>description: </strong> {{item.description}}
    </p>
  </div>
</div>
