import { Injectable, OnDestroy } from "@angular/core";
import { AbstractControl, FormBuilder, FormGroup } from "@angular/forms";
import { MatSnackBar } from "@angular/material/snack-bar";
import { ParamMap } from "@angular/router";
import { Subscription, concatMap, switchMap } from "rxjs";
import { WellRequestStatus_Options } from "../../model/status-enum.model";
import { WellRequestLog } from "../../model/well-request-log.model";
import { PendingRequest } from "../../model/well-request-pending.model";
import { BackendService } from "../../services/backend.service";
import { RoutingService } from "../../services/routing.service";
import { WellRequest } from "../../model/well-request.model";
import { EmailService } from "../../services/email.service";
import { environment } from "../../../environments/environment";
import { StatusHelperService } from "../../services/status-helper.service";

@Injectable({
  providedIn: 'root',
})
export abstract class WellReuqestBase implements OnDestroy {
  private approvalSubscription: Subscription | undefined;

  form!: FormGroup;
  isFormDisabled: boolean = false;

  constructor(
    protected routingService: RoutingService,
    protected snackBar: MatSnackBar,
    protected fb: FormBuilder,
    protected backendService: BackendService,
    protected email: EmailService) {
  }

  ngOnDestroy(): void {
    this.approvalSubscription?.unsubscribe();
    }

  handelLogRequest(action: Function, emailSendFunc: Function ) {
    var log = new WellRequestLog();
    this.routingService.activatedRoute.paramMap
      .pipe(
        switchMap((params: ParamMap) => {
          log = this.createLog(params);
          return action(log);
        })
      )
      .subscribe({
        next: () => {
          emailSendFunc(log)
        },
        error: () => {
          this.errorCall();
        }
      });
  }

  protected sendEmail(log: WellRequestLog | undefined): undefined {
    if (log?.wellRequestId)
      this.email.EmailNotifyAll(BigInt(log.wellRequestId), undefined);
    this.redirect();
    return ;
  }

  protected validate(control: AbstractControl<any>): boolean {
    if (!control)
      return false;

    let value = control.getRawValue();
    let isValid: boolean = value !== undefined && value !== null && value.replace(/\s/g, '').length > 0;

    if (!isValid) {
      control.setErrors({ "incorrect": true });
      control.markAllAsTouched();
    }

    return isValid;
  }

  protected createLog(params: ParamMap): WellRequestLog {
    this.isFormDisabled = true;
    let log: WellRequestLog = new WellRequestLog();
    log.comments = this.form.getRawValue().comments;
    log.wellRequestId = parseInt(params.get('id') ?? "");
    return log
  }

  protected resetValidation(control: AbstractControl<any>): void {
    control?.setErrors(null);
  }

  createPendingRequest(statusToUpdate: WellRequestStatus_Options, wellRequestId: bigint) {
    const pendingRequest = new PendingRequest();
    pendingRequest.statusToUpdate = statusToUpdate;
    pendingRequest.wellRequestId = wellRequestId;
    pendingRequest.comments = this.form.getRawValue().comments.toString();

    this.approvalSubscription = this.backendService.approveWellRequest(pendingRequest)
      .pipe(
        concatMap(async () => {
          await this.email.EmailNotifyAll(wellRequestId, undefined);
          await this.email.EmailNotifyAll(wellRequestId, pendingRequest.statusToUpdate)
        })
      )
      .subscribe({
        complete: () => {
          this.redirect();
        },
        error: (error) => {
          console.error(error.error);
        }
      });
  }

   redirect() {
    this.form.reset();
    this.routingService.adminRedirect();
  }

  private errorCall() {
    this.isFormDisabled = false;
    this.snackBar.open("Failed", "Close", {
      duration: 5000,
      verticalPosition: 'bottom',
      horizontalPosition: 'end'
    })
  }
}
