<div class="admin-container">
  <ng-template #emptyField>
    <div class="well-info-item">
      <span>N/A</span>
    </div>
  </ng-template>

  <mat-label>Approval Admins</mat-label><br/>
  <div *ngIf="!isEmtpy;else emptyField" class="email-contrainer">
    <span *ngFor="let admin of admins; last as isLast">
      <a [href]="admin.email">{{ admin.name }}</a>
      <span *ngIf="!isLast">, </span>
    </span>
  </div>
</div>
