import { Injectable, inject } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, CanActivateFn, Router, RouterStateSnapshot, ActivatedRoute } from '@angular/router';
import { PermissionsService } from '../services/permissions.service';
import { RoutingService } from '../services/routing.service';

export const cliveGuard_CanManageWellRequests: CanActivateFn = (route, state) => {
  return inject(PermissionsService).CheckUserPermission('WellRequests_CanManage');
    //.pipe(catchError((err, caught$) => of('error', err)));
  //if you need, in future you can extend the guard with backend validations, like a user can cancel only his requests:
  //  return inject(UserPermissionsService).UserCanCancel('WellRequests_CanCancel', route.params['requestId']);
  //but in most of the cases this can be handled at UI level and backend request validations.
};

export const cliveGuard_AdminInRegion: CanActivateFn = (route, state) => {
  return inject(PermissionsService).CheckUserPermission('WellRequests_AdminInGroups');
};
