import { Injectable } from "@angular/core";
import { BackendService } from "../../services/backend.service";
import { RoutingService } from "../../services/routing.service";
import { ActivatedRouteSnapshot } from "@angular/router";
import { PermissionsService } from "../../services/permissions.service";

@Injectable({
  providedIn: 'root',
})
export abstract class WellGuardBase  {
 
  constructor(
    private _permissionsService: PermissionsService,
    private _routingService: RoutingService,
    ) {
  }

  async isValidRedirect(route: ActivatedRouteSnapshot, wellPermissionType: any): Promise<boolean> {
    const id = parseInt(route.paramMap.get('id') || "-1");

    try {
      const isPermissionGranted = await this._permissionsService.CheckWellRequstPermission(wellPermissionType, id);
      if (isPermissionGranted) {
        return true;
      } else {
        this._routingService.tabRedirect(route);
        return false;
      }
    } catch (error) {
      console.error('Error in permission check:', error);
      return false;
    }
  }
}
