import { Injectable, Inject, OnDestroy, OnInit } from "@angular/core";
import { PermissionsService } from "./permissions.service";
import { MsalService, MsalBroadcastService, MSAL_GUARD_CONFIG, MsalGuardConfiguration } from "@azure/msal-angular";
import { AccountInfo, AuthenticationResult, EventMessage, EventType, InteractionStatus, RedirectRequest } from "@azure/msal-browser";
import {  Observable, Subject } from "rxjs";
import { EmailInfo } from "../model/well-request-email.model";
import { BackendService } from "./backend.service";
import { environment } from "../../environments/environment";
import { StatusHelperService } from "./status-helper.service";
import { WellRequestStatus_Options } from "../model/status-enum.model";


@Injectable({
  providedIn: 'root'
})
export class EmailService{
  private _emailToken!: AuthenticationResult;
  constructor(private readonly _backendService: BackendService,
    private readonly _msalService: MsalService,) {
    this.init()
  }

  private init(): void {
    this.setEmailToken();
  }

  async EmailNotify(wellRequestId: bigint, isForAdmin: boolean, hallIds?: string[]) {
    this.tokenCheckValid(this._emailToken);
    await this._backendService.getWellRequest(wellRequestId.toString()).subscribe((result) => {
      var email = new EmailInfo();
      email.emailToken = this._emailToken.accessToken;
      email.userLink = environment.msalConfig.auth.redirect + '#' + StatusHelperService.GetHrefByStatus(result.currentStatusId, false) + wellRequestId;
      email.adminLink = environment.msalConfig.auth.redirect + '#' + StatusHelperService.GetHrefByStatus(result.currentStatusId, true) + wellRequestId;
      email.wellRequestId = wellRequestId.toString();
      email.isForAdmin = isForAdmin;
      email.adminsHallIds = hallIds;
      this._backendService.emailNotify(email).subscribe({
        error: (err) => { console.error(err.error) }
      });
    })
  }

  async EmailNotifyAll(wellRequestId: bigint, statusAfterRts: WellRequestStatus_Options | undefined) {
    this.tokenCheckValid(this._emailToken);
    await this._backendService.getWellRequest(wellRequestId.toString()).subscribe((result) => {
      var email = new EmailInfo();
      email.emailToken = this._emailToken.accessToken;
      email.userLink = environment.msalConfig.auth.redirect + '#' + StatusHelperService.GetHrefByStatus(result.currentStatusId, false) + wellRequestId;
      email.adminLink = environment.msalConfig.auth.redirect + '#' + StatusHelperService.GetHrefByStatus(statusAfterRts ?? result.currentStatusId, true) + wellRequestId;
      email.wellRequestId = wellRequestId.toString();
      email.statusAfterRts = statusAfterRts;
      email.isDelay = statusAfterRts ? true : false;
       
      this._backendService.emailNotifyAll(email).subscribe({
        error: (err) => { console.error(err.error) }
      });
    })
  }

  private tokenCheckValid(token: AuthenticationResult) {
    if (token && token.accessToken && token.expiresOn) {
      const expirationTime = token.expiresOn.getTime() + token.expiresOn.getTimezoneOffset() * 60000;
      var now = new Date();
      const currentTime = now.getTime() + now.getTimezoneOffset() * 60000;
      const fiveMinutesInMillis = 5 * 60 * 1000;

      if (!(expirationTime - currentTime > fiveMinutesInMillis))
        this.setEmailToken();
    }
  }

  private setEmailToken(): void {
    const accessTokenRequest = {
      scopes: environment.msGraphConfig.scopes
    };

    this._msalService.acquireTokenSilent(accessTokenRequest).subscribe({
      next: (response: AuthenticationResult) => {
        this._emailToken = response;
      },
      error: (error: any) => {
        console.error(error.error);
      }
    });
  }
}
