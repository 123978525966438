import { Component, Input, ViewChild, forwardRef } from '@angular/core';
import { IBaseEntity } from 'src/app/model/base-entity.model';
import { CdkVirtualScrollViewport } from '@angular/cdk/scrolling';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { MatAutocomplete, MatAutocompleteTrigger } from '@angular/material/autocomplete';

@Component({
  selector: 'app-select',
  templateUrl: './select.component.html',
  styleUrls: ['./select.component.css'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => SelectComponent),
      multi: true     
    }]
})
export class SelectComponent implements ControlValueAccessor {
  @ViewChild(MatAutocomplete)
  autocomplete!: MatAutocomplete;

  @ViewChild(MatAutocompleteTrigger)
  autocompleteTrigger!: MatAutocompleteTrigger;

  @ViewChild("matAutocompleteViewport")
  virtualScrollViewport!: CdkVirtualScrollViewport;

  @Input() set dataSource(value: IBaseEntity[]) {
    this.dataSourceItems = value;
    this.items = value.slice();

    if (typeof this._val === 'string' && this._val != '') 
      this.items = this.filter(this._val as string);
    else if (this._val?.id)
      this.items = this.filter(this._val.name as string);
  }

  @Input() set label(value: string) {
    this.labelValue = value;
  }

  @Input() set placeholder(value: string) {
    this.placeholderValue = value;
  }

  items!: IBaseEntity[];
  dataSourceItems!: IBaseEntity[];
  labelValue: string = "";
  placeholderValue: string = "";
  disabled: boolean = false;

  private _val: any;

  get value(): any {
    return this._val;
  }

  set value(val: any) {
    if (this._val !== val) {
      this._val = val;

      this.onChange(val);
      this.onTouch(val);

      const name = typeof val === 'string' ? val : val?.name;
      this.items = name ? this.filter(name as string) : this.dataSourceItems.slice();
    }
  }

  writeValue(value: any) { 
    this.value = value
  }

  registerOnChange(fn: any) {
    this.onChange = fn
  }

  registerOnTouched(fn: any) {
    this.onTouch = fn
  }

  private onChange: any = () => {};

  private onTouch: any = () => {};

  displayFn(item: IBaseEntity): string {
    return item && item.name ? item.name : '';
  }

  onOpened() {
    this.virtualScrollViewport.checkViewportSize();
    this.autocompleteTrigger._handleKeydown = (event) => {
      if (event.key === "Tab") {
        this.autocomplete._keyManager.onKeydown(event);
      }
    };
  }

  private filter(value: string): IBaseEntity[] {
    const filterValue = value.toLowerCase();
    return this.dataSourceItems.filter(item => item && item.name && item.name.toLowerCase().includes(filterValue));
  }

  setDisabledState(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }
}
