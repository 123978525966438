import { Injectable } from '@angular/core';
import { MsalService } from '@azure/msal-angular';
import { BackendService } from '../services/backend.service';
import { Observable, lastValueFrom, map, of } from 'rxjs';
import { Router } from '@angular/router';

type UserPermissionTypes = "WellRequests_CanManage" | "WellRequests_AdminInGroups";
type WellPermissionTypes = "WellRequest_CanView" | "WellRequest_CanEdit" | "WellRequest_CanApprove" | "WellRequest_CanCancel" | "WellRequest_CanViewAdmin" | "WellRequest_AdminInRegion";

@Injectable({
  providedIn: 'root'
})
export class PermissionsService {
  private _userPermissions: Record<string, string> | undefined;
  private _wellRequestPermissions: Record<string, string> | undefined;
  constructor(
    private _msalService: MsalService,
    readonly _backendService: BackendService,
    public router: Router
  )
  { }

   public GetUserPermission(): Observable<Record<string, string>> {
    return this._backendService.getUserPermissions()
      .pipe(map(_ => this._userPermissions = _));
  };
  public GetWellRequestPermission(id: number): Observable<Record<string, string>> {
    return this._backendService.getWellRequestPermissions(id.toString())
      .pipe(map(_ => this._wellRequestPermissions = _));
  };
  public Clear(): void {
    this._userPermissions = {} as Record<string, string>;
    this._wellRequestPermissions = {} as Record<string, string>; 
  }

  //public CheckUserPermission(key: UserPermissionTypes): Observable<boolean> {
  //  if (this._msalService.instance.getActiveAccount() == null)
  //    return of(false);

  //  if (!this._userPermissions)
  //    this.Get().subscribe(_ => this._userPermissions = _);

  //  return of(this.HasUserPermissions(key));
  //}
  public async CheckUserPermission(key: UserPermissionTypes): Promise<boolean> {
    if (this._msalService.instance.getActiveAccount() == null)
      return false;

    if (!this._userPermissions) {
      const data$ = this.GetUserPermission();
      this._userPermissions = await lastValueFrom(data$);
    }

    return this.HasUserPermissions(key);
  }

  public async CheckWellRequstPermission(key: WellPermissionTypes, id: number): Promise<boolean> {
    if (this._msalService.instance.getActiveAccount() == null || id == -1)
      return false;
    
    const data$ = this.GetWellRequestPermission(id);
    this._wellRequestPermissions = await lastValueFrom(data$);   

    return this._wellRequestPermissions != undefined && key in this._wellRequestPermissions;
  }

  public HasUserPermissions(key: string): boolean {
    return (this._userPermissions != undefined && key in this._userPermissions);
  }

  public HasWellRequestPermissions(key: string): boolean {
    return (this._wellRequestPermissions != undefined && key in this._wellRequestPermissions);
  }

  public GetUserPermissionValue(key: string): string {
    if (this.HasUserPermissions(key))
      return this._userPermissions![key] ?? '';
    return '';
  }
}
