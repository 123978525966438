import { Location } from '@angular/common';
import { Injectable } from '@angular/core';
import { ActivatedRoute, ActivatedRouteSnapshot, Router } from '@angular/router';
import { Observable, ReplaySubject } from 'rxjs';
import { BackendService } from './backend.service';
import { PermissionsService } from './permissions.service';


@Injectable({
  providedIn: 'root'
})
export class RoutingService {
  private routeMapBackUser: [RegExp, Function][] = [
    [/^\/well-request\/new(?:\?.*)?$/,() => { this.router.navigate(['/well-request/my']) }],
    [/^\/well-request\/edit(?:\/\d+)?$/, () => { this.router.navigate(['/well-request/my']) }],
    [/^\/well-request\/view\/\d+$/, () => { this.router.navigate(['/well-request/my']) }],
    [/^\/well-request\/log(?:\/\d+)?$/, () => { this.navigateHistoryBack() }],
    [/^\/well-request\/approve\/\d+$/, () => { this.router.navigate(['/well-request/my']) }],
    [/^\/well-request\/review\/\d+$/, () => { this.router.navigate(['/well-request/my']) }],
  ];

  private routeMapBackAdmin: [RegExp, Function][] = [
    [/^\/well-request\/new(?:\?.*)?$/, () => { this.router.navigate(['/well-request/my']) }],
    [/^\/well-request\/edit(?:\/\d+)?$/, () => { this.router.navigate(['/well-request/my']) }],
    [/^\/well-request\/approve\/\d+$/, () => { this.router.navigate(['/well-request/pending'])}],
    [/^\/well-request\/review\/\d+$/, () => { this.router.navigate(['/well-request/all']) }],
    [/^\/well-request\/view\/\d+$/, () => { this.router.navigate(['/well-request/my']) }],
    [/^\/well-request\/log(?:\/\d+)?$/, () => { this.navigateHistoryBack() }]
  ];

  private wellReuqestTablePermissions = new Map<any, string>( [
    [ 'WellRequest_CanViewAdmin',  '/well-request/review' ],
    [ 'WellRequest_CanView', '/well-request/view' ],
    ['WellRequest_CanApprove', '/well-request/approve'],
    ['WellRequest_CanEdit', '/well-request/edit']
  ]);

  private static _tabToBack: ReplaySubject<string>;
  private static _path: string | undefined;
  constructor(public router: Router,
    private _backendService: BackendService,
    public activatedRoute: ActivatedRoute,
    private _location: Location,
    private _permisstionService: PermissionsService) {
    if (!RoutingService._tabToBack) {
      RoutingService._tabToBack = new ReplaySubject<string>();
      this.getRouteValue();
    }
  }

  static get tabToBack$(): Observable<string> {
    if (!RoutingService._tabToBack) {
      RoutingService._tabToBack = new ReplaySubject<string>();
    }
    return RoutingService._tabToBack.asObservable();
  }

  back() {
    if (!RoutingService._path)
      this.routeMapper(this.router.url, this.getWellRequestId().toString());
    else
      this._location.back();
  }

  adminRedirect() {
    this.redirect('/well-request/pending');    
  }

  cancelRedirect() {
    this.redirect('/well-request/my');    
  }

  tabRedirect(route: ActivatedRouteSnapshot) {
    if (RoutingService._path)
      this.router.navigate([RoutingService._path]);
    else {
      const id = parseInt(route.paramMap.get('id') || "-1");
      const fullUrl: string = '/' + route.url.map(segment => segment.path).join('/');
      this.routeMapper(fullUrl, id.toString())
    }
  }

  async forwardRedirect(wellRequestId: string) {
    for (const pair of this.wellReuqestTablePermissions) {
      if (await this._permisstionService.CheckWellRequstPermission(pair[0], parseInt(wellRequestId))) {
        this.router.navigate([pair[1], wellRequestId]);
        return; 
      }
    }
  }

  setRoute(status: string) {
    RoutingService._tabToBack.next(status);
  }

  getWellRequestId(): number {
    return parseInt(this.activatedRoute.snapshot.paramMap.get('id') ?? "-1");
  }

  private navigateHistoryBack(): void {
    const id = this.getWellRequestId();
    this.forwardRedirect(id.toString());
  }

  private redirect(tabPath: string) {
    if (!RoutingService._path)
      this.router.navigate([tabPath]);
    else
      this.router.navigate([RoutingService._path]);
  }

  private getRouteValue() {
    RoutingService.tabToBack$.subscribe((data) => {
      RoutingService._path = data;
    });
  }

  private routeMapper(route: string, wellRequestId: string) {
    if (wellRequestId == '-1') {
      this.setTab(this.routeMapBackUser, route);
      return;
    }

    this._backendService.getCheckIsAdmins(wellRequestId).subscribe((isAdmin) => {
      if (isAdmin)
        this.setTab(this.routeMapBackAdmin, route)
      else
        this.setTab(this.routeMapBackUser, route)
    })
     
    this.routeMapBackUser
  }

  private setTab(routeMap: [RegExp, Function][], route: string) {
    routeMap.forEach(([pattern, func]) => {
      if (pattern.test(route)) {
        func();
      }
    });
  }
}
