import { IGroup } from "../../../model/group.model";
import { WellRequestBaseHandler } from "./well-request-handler-base";

export class WellRequestGroupHandler extends WellRequestBaseHandler {

    groupChangeHandler(groupData: any): void {
        let countryId = this.wellRequestComp.countryControl?.value?.id;

        if (groupData?.id) {
          const groups = [groupData];

          if (groupData?.countryId && !countryId) {
              const country = this.wellRequestComp.patchCountry(groupData.countryId.toString());
              countryId = country?.id;
              this.wellRequestComp.selectedGroups = groups;

              if (country?.name && groupData?.regionId)
                  this.countryFilter(country?.name, groupData.regionId);
          }

          this.wellRequestComp.filteredGroups = this.isCountryMapped(countryId)
              ? this.wellRequestComp.groups.filter(x => x.countryId == groupData.countryId)
              : this.wellRequestComp.groups.filter(x => x.regionId == groupData.regionId);

          this.wellRequestComp.getAdminsByGroups(groups);
        }
    }

    async groupRemoveHandler(groupToRestore: IGroup[]) {

        if (this.wellRequestComp.selectedAdmins.length > 0 && this.wellRequestComp.regionControl.value) {
            this.wellRequestComp.closeEvent.emit(true);
            this.openDialogUpdate().subscribe((result) => {
                this.wellRequestComp.closeEvent.emit(false);
                if (!result) {
                    this.wellRequestComp.selectedGroups = groupToRestore;
                    return;
                }
                this.groupRemoved();
            });
        } else 
            this.groupRemoved();
    }

    groupSelectedHandler(groups: IGroup[]) {
          this.wellRequestComp.getAdminsByGroups(groups);
          this.getRigsByGroups(groups);
      }

    private groupRemoved() {
        this.wellRequestComp.getAdminsByGroups(this.wellRequestComp.selectedGroups);
        this.updateRigs();
      }

    private updateRigs() {
        if (!this.wellRequestComp.selectedGroups.map(x => x.id)
            .includes(this.wellRequestComp.rigControl.value?.groupId)) {

            this.wellRequestComp.rigControl.reset('');

            if (this.wellRequestComp.selectedGroups.length != 0)
                this.getRigsByGroups(this.wellRequestComp.selectedGroups);
        }

        if (this.wellRequestComp.selectedGroups.length == 0)
            this.getRigsByCountry(this.wellRequestComp.countryControl.value);
    }
  }
