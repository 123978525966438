import { Component, forwardRef, Input } from '@angular/core';
import { FormGroup, NG_VALUE_ACCESSOR } from '@angular/forms';
import { StatusHelperService } from '../../../services/status-helper.service';
import { IStatus } from '../../../model/status.model';
import { MatSelectChange } from '@angular/material/select';
import { WellRequest } from '../../../model/well-request.model';
import { PermissionsService } from '../../../services/permissions.service';

@Component({
  selector: 'app-status-select',
  templateUrl: './status-select.component.html',
  styleUrls: ['./status-select.component.css'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => StatusSelectComponent),
      multi: true
    },
  ],
})
export class StatusSelectComponent  {
  form!: FormGroup;
  statusOptions: IStatus[] = [];
  preselectedStatus: IStatus | undefined;
  disabledField: boolean = true;

  private _wellRequest:  WellRequest | undefined;

  @Input()
  set wellRequest(value: WellRequest | undefined) {
    this._wellRequest = value;
    this.init();
  }

  constructor(private statusItem: StatusHelperService, private permission: PermissionsService) { }

  private init(): void {
    this.permission.CheckWellRequstPermission('WellRequest_CanViewAdmin', parseInt(this._wellRequest?.id?.toString() ?? "-1"))
      .then((result: boolean) => {
        this.statusOptions = StatusHelperService.GetStatusList(this._wellRequest?.currentStatusId, result);
        if (this.statusOptions.length > 1)
          this.disabledField = false;
      });

    this.preselectedStatus = StatusHelperService.GetStatus(this._wellRequest?.currentStatusId);
  }

  change(event: MatSelectChange) {
    this.statusItem.changeStatus(event.value.id);
  }

  compareFn(option1: IStatus, option2: IStatus): boolean {
    if (option2 == null)
      return true;
    return option1 && option2 ? option1.id === option2.id : option1 === option2;
  }
}
