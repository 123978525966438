import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { BrowserUtils } from '@azure/msal-browser';
import { ProfileComponent } from './profile/profile.component';
import { AccessDeniedComponent } from './common/access-denied/access-denied.component';
import { WellRequestComponent } from './wells/well-request/well-request.component';
import { cliveGuard_CanManageWellRequests, cliveGuard_AdminInRegion} from './guards/clive.guard';
import { WellApproveComponent } from './wells/well-approve/well-approve.component';
import { WellDetailsComponent } from './wells/well-details/well-details.component';
import { MsalGuard } from '@azure/msal-angular';
import { WellRequestTableAllComponent } from './wells/well-request-table/well-request-table-all.component';
import { WellRequestTableMyComponent } from './wells/well-request-table/well-request-table-my.component';
import { WellRequestTablePendingComponent } from './wells/well-request-table/well-request-table-pending.component';
import { WellAdminsDetailsComponent } from './wells/well-admin-details/well-admin-details.component';
import { WellHistoryLogComponent } from './wells/well-history-log/well-history-log.component';
import { ShellComponent } from './shell/shell.component';
import { cliveGuard_CanManageOwnWellRequests } from './guards/guards-well-request/can-manage-own.guard';
import { cliveGuard_CanViewWellRequests } from './guards/guards-well-request/can-view.guard';
import { cliveGuard_CanApproveWellRequests } from './guards/guards-well-request/can-approve.guard';
import { cliveGuard_CanAdminViewWellRequests } from './guards/guards-well-request/can-admin-view.guard';


const routes: Routes = [
  { 
    path: '',
    component: ShellComponent,
    canActivate: [MsalGuard],
    children: [
      { path: '', redirectTo: 'well-request/my', pathMatch: 'full' },
      { path: 'well-request/all', component: WellRequestTableAllComponent, canActivate: [MsalGuard, cliveGuard_CanManageWellRequests] },
      { path: 'well-request/my', component: WellRequestTableMyComponent, canActivate: [MsalGuard, cliveGuard_CanManageWellRequests] },
      { path: 'well-request/pending', component: WellRequestTablePendingComponent, canActivate: [MsalGuard, cliveGuard_AdminInRegion, cliveGuard_CanManageWellRequests] },
      { path: 'well-request/new', component: WellRequestComponent, canActivate: [MsalGuard, cliveGuard_CanManageWellRequests] },
      { path: 'well-request/edit/:id', component: WellRequestComponent, canActivate: [MsalGuard, cliveGuard_CanManageWellRequests, cliveGuard_CanManageOwnWellRequests] },
      { path: 'well-request/view/:id', component: WellDetailsComponent, canActivate: [MsalGuard, cliveGuard_CanManageWellRequests, cliveGuard_CanViewWellRequests] },
      { path: 'well-request/approve/:id', component: WellApproveComponent, canActivate: [MsalGuard, cliveGuard_CanManageWellRequests, cliveGuard_AdminInRegion, cliveGuard_CanApproveWellRequests] },
      { path: 'well-request/review/:id', component: WellAdminsDetailsComponent, canActivate: [MsalGuard, cliveGuard_CanManageWellRequests, cliveGuard_AdminInRegion, cliveGuard_CanAdminViewWellRequests] },
      { path: 'well-request/log/:id', component: WellHistoryLogComponent, canActivate: [MsalGuard, cliveGuard_CanManageWellRequests] },
      { path: 'profile', component: ProfileComponent, canActivate: [MsalGuard] }
    ] },
  { path: 'access-denied', component: AccessDeniedComponent },
  { path: '**', redirectTo: '' }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {
    useHash: true,
    // Don't perform initial navigation in iframes or popups
    initialNavigation: !BrowserUtils.isInIframe() && !BrowserUtils.isInPopup() ? 'enabledNonBlocking' : 'disabled' // Set to enabledBlocking to use Angular Universal
  })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
