import { HttpClient, HttpErrorResponse, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, catchError, map, throwError } from 'rxjs';
import { environment } from 'src/environments/environment';
import { ErrorHandlerService } from '../common/services/error-handler.service';
import { IWellEnvironment } from '../model/well-environment.model';
import { IOperator } from '../model/operator.model';
import { ICountry } from '../model/country.model';
import { IRig } from '../model/rig.model';
import { IUser } from '../model/user.model';
import { IWellRequestStatus } from '../model/well-request-status.model';
import { WellRequest } from '../model/well-request.model';
import { WellRequestLog } from '../model/well-request-log.model';
import { IWellRequestTable } from '../model/well-request-table.model';
import { PendingRequest } from '../model/well-request-pending.model';
import { IRegion } from '../model/region.model';
import { Router } from '@angular/router';
import { EmailInfo } from '../model/well-request-email.model';
import { IGroup } from '../model/group.model';
import { IAdmin } from '../model/admin.model';

@Injectable({
  providedIn: 'root'
})
export class BackendService {
  private _baseUrl: string | undefined

  constructor(
    private _http: HttpClient,
    private _errorHandlerService: ErrorHandlerService,
    private readonly _router: Router) {
    this.setBaseUrl();
  }
  //Anonymous (check API And DB Integrity)
  getPing = (): Observable<string> => this.get$<string>("Ping");
  getWellRequestStatus = (): Observable<IWellRequestStatus[]> => this.get$<IWellRequestStatus[]>("WellRequest/WellRequestStatus");
  getWellEnvironment = (): Observable<IWellEnvironment[]> => this.get$<IWellEnvironment[]>("DropDownLists/WellEnvironments");
  getRegions = (): Observable<IRegion[]> => this.get$<IRegion[]>("DropDownLists/Region");
  getRegion = (regionId: string): Observable<IRegion> => this.get$<IRegion>("DropDownLists/Region/" + regionId);
  getCountries = (): Observable<ICountry[]> => this.get$<ICountry[]>("DropDownLists/Countries");
  getCountry = (countryId: string): Observable<ICountry> => this.get$<ICountry>("DropDownLists/Country?countryId=" + countryId);
  getGroups = (): Observable<IGroup[]> => this.get$<IGroup[]>("DropDownLists/Groups");
  getOperators = (): Observable<IOperator[]> => this.get$<IOperator[]>("DropDownLists/Operators");
  getOperator = (operatorId: string): Observable<IOperator> => this.get$<IOperator>("DropDownLists/Operator?operatorId=" + operatorId);
  getRigs = (): Observable<IRig[]> => this.get$<IRig[]>("DropDownLists/Rigs");
  getRig = (rigId: string): Observable<IRig> => this.get$<IRig>("DropDownLists/Rig?rigId=" + rigId);
  createWellRequest = (wellRequest: WellRequest): Observable<bigint> => this.post$<WellRequest, bigint>("WellRequest/Create", wellRequest);
  updateWellRequest = (wellRequest: WellRequest) => this.put$<WellRequest>("WellRequest/Update", wellRequest);
  isOwnerWellRequest = (wellRequestId: string) => this.get$<boolean>("WellRequest/Owner", { wellRequestId: wellRequestId });
  cancelWellRequest = (wellRequestId: string) => this.put$<string>("WellRequest/Cancel?wellRequestId=" + wellRequestId);
  approveWellRequest = (pendingRequest: PendingRequest) => this.put$<PendingRequest>("WellRequest/Approve", pendingRequest);
  rejectWellRequest = (wellRequestLog: WellRequestLog) => this.put$<WellRequestLog>("WellRequest/Reject", wellRequestLog);
  requestChanges = (wellRequestLog: WellRequestLog) => this.put$<WellRequestLog>("WellRequest/RequestChanges", wellRequestLog);
  logDuplicate = (wellRequestLog: WellRequestLog) => this.put$<WellRequestLog>("WellRequest/LogDuplicate", wellRequestLog);
  getWellRequestTable = (): Observable<IWellRequestTable[]> => this.get$<IWellRequestTable[]>("WellRequest/WellRequestTable");
  getMyWellRequestTable = (userId: string | undefined): Observable<IWellRequestTable[]> => this.getParams$<IWellRequestTable[]>("WellRequest/WellUserRequestTable", { UserId: userId });
  getPendingWellRequestTable = (userId: string | undefined): Observable<IWellRequestTable[]> => this.getParams$<IWellRequestTable[]>("WellRequest/WellAdminRequestTable", { UserId: userId });
  getWellRequest = (wellRequestId: string): Observable<WellRequest> => this.getParams$<WellRequest>("WellRequest/" + wellRequestId);
  getWellRequestLog = (wellRequestId: number): Observable<WellRequestLog> => this.getParams$<WellRequestLog>("WellRequest/WellRequestLog", { wellRequestId: wellRequestId });
  GetWellRequestAdmins = (wellRequestId: string | undefined): Observable<IUser[]> => this.get$<IUser[]>("Admin/WellRequest/" + wellRequestId);
  getAdmins = (): Observable<IAdmin[]> => this.get$<IAdmin[]>("Admin/All/");
  getAdminsByGroups = (ids: string[]): Observable<IAdmin[]> => this.get$<IAdmin[]>("Admin/ByGroups", { groupIds: ids });
  updateAdmins = (adminIds: string[], wellRequestId: string): Observable<any> => this.post$<any, any>("Admin/Update", { adminIds, wellRequestId });
  getCheckIsAdmins = (wellRequestId: string | undefined): Observable<IUser[]> => this.get$<IUser[]>("Admin/Group/" + wellRequestId);
  emailNotify = (emailInfo: EmailInfo): Observable<EmailInfo> => this.post$<EmailInfo, any>("Email/EmailNotification/", emailInfo);
  emailNotifyAll = (emailInfo: EmailInfo): Observable<EmailInfo> => this.post$<EmailInfo, any>("Email/EmailNotificationAll/", emailInfo);

  //Require AccessToken
  getUserPermissions = (): Observable<Record<string, string>> => this.get$<Record<string, string>>("Permissions");
  getWellRequestPermissions = (id: string): Observable<Record<string, string>> => this.getParams$<Record<string, string>>("Permissions/GetWellRequestPermissions", { id: id });

  private setBaseUrl() {
    this._baseUrl = environment.backendConfig.url;
    if (!this._baseUrl.endsWith('/')) this._baseUrl += '/';
}

  private get$<T>(url: string, params?: any): Observable<T> {

    const queryParams = params ? new HttpParams({ fromObject: params }) : params; 
    
    const options = this.prepareHttpOptions(null, queryParams);

    return this._http.get<T>(this._baseUrl + url,  options )
      .pipe(
        //retry(2),
        catchError((err, caught$) => this.errorHandler(err, this))
      );
  }


  private post$<T, U>(url: string, body?: T): Observable<U> {
   const options = this.prepareHttpOptions();
   return this._http.post<U>(this._baseUrl + url, body, options)
     .pipe(
       catchError(err => this.errorHandler(err, this))
     );
  }

  private put$<T>(url: string, body?: T): Observable<T> {
    const options = this.prepareHttpOptions();
    return this._http.put<T>(this._baseUrl + url, body ?? {}, options)
      .pipe(
        catchError(err => this.errorHandler(err, this))
      );
   }

  private getParams$<T>(url: string, params?: any): Observable<T>
  {
    const options = this.prepareHttpOptions();
    const queryParams = new HttpParams({ fromObject: params }); 
    const fullUrl = this._baseUrl + url + '?' + queryParams.toString();

    return this._http.get<T>(fullUrl, options)
      .pipe(
        catchError((err, caught$) => this.errorHandler(err, this))
      );
  }

  private prepareHttpOptions(headers?: HttpHeaders | null, params?: HttpParams | null): object {
    headers = (headers || new HttpHeaders())
        .set('Content-Type', 'application/json')
        .set('Accept', 'application/json')
        .set("Access-Control-Allow-Origin", "*")
        .set("Access-Control-Allow-Methods", "GET,HEAD,OPTIONS,POST,PUT")
        .set("Access-Control-Allow-Headers", "Origin, X-Requested-With, Content-Type, Accept, x-client-key, x-client-token, x-client-secret, Authorization")
    //headers = headers.set('Authorization', 'Bearer {token}');// using interceptor from MSAL
    return {
      headers: headers,
      params: params
    }
  }

  /** Error Handling method */
  private errorHandler(error: HttpErrorResponse, scope: this) {
    //TITLE: 'Something bad happened; please try again later.';
    if (error.error instanceof ErrorEvent) {
      // A client-side or network error occurred. Handle it accordingly.
      console.error("ERR1: An error occurred:", error.error.message);
      scope._errorHandlerService.notify_ErrorDetails(`ERR1: An error occurred: ${ error.error.message }`, "Backend");
      
    } else {
      // The backend returned an unsuccessful response code.
      // The response body may contain clues as to what went wrong,
      console.error(`ERR2: Backend returned code ${error.status}, body was: ${error.error}`);
      if (error.status === 403)
        scope._router.navigateByUrl('/access-denied');
      else
        scope._errorHandlerService.notify_ErrorDetails(`ERR2: Backend returned code ${error.status}, body was: ${JSON.stringify(error.error)}`, "Backend");
    }
    // ...optionally return a default fallback value so app can continue (pick one)
    // which could be a default value
    // return Observable.of<any>({my: "default value..."});
    // or simply an empty observable
    //return Observable.empty<T>();
    // return an observable with a user-facing error message
    return throwError(() => error);
  }
}
