<div>
  <app-back-button></app-back-button>

  <div class="details-container" *ngIf="wellRequest$ | async as wellRequest">
    <app-well-details-header style="width:950px;"  [wellRequest]="wellRequest"></app-well-details-header>
    <div class="cancel-button" *ngIf ="canCancel()">
      <app-cancel-request-button  *ngIf="!wellRequest?.isInRts" [wellRequestId]="wellRequest?.id"></app-cancel-request-button>
    </div>
    <div>
      <app-well-details-admin></app-well-details-admin>
      <mat-divider></mat-divider>
    </div>
    <div class="details-info-container">
      <app-well-details-info [wellRequest]="wellRequest"></app-well-details-info>
    </div>
  </div>
  
  <form class="form" [formGroup]="form" >
    <div class="well-info-item">
      <mat-label for="comments">Comments<span class="validation-hint">&nbsp;*Required when request changes and or reject</span></mat-label><br />
      <mat-form-field appearance="outline" subscriptSizing="dynamic" class="comments">
        <textarea matInput
                  formControlName="comments"
                  cdkTextareaAutosize
                  #autosize="cdkTextareaAutosize"
                  cdkAutosizeMinRows="6"
                  cdkAutosizeMaxRows="6"
                  maxlength="250"
                  (keydown.enter)="onCommentsEnter($event)">
          </textarea>
      </mat-form-field>
    </div>
   
    <div class="form-buttons">
      <button mat-raised-button type="submit" color="primary" class="submit-button primary-button" [disabled]="isFormDisabled" (click)="openDialogApprove()">Approve</button>
      &nbsp;
      <button mat-raised-button type="button" color="accent" class="submit-button accent-button" (click)="requestChangesSubmit()">Request Changes</button>
      &nbsp;
      <button mat-raised-button type="button" color="accent" class="submit-button accent-button reject" (click)="openDialogReject()">Reject</button>
    </div>
  </form>
</div>
