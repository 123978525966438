import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { IUser } from 'src/app/model/user.model';
import { BackendService } from 'src/app/services/backend.service';
import { RoutingService } from '../../../services/routing.service';

@Component({
  selector: 'app-well-details-admin',
  templateUrl: './well-details-admin.component.html',
  styleUrls: ['../well-details.component.css', 'well-details-admin.component.css'],
  providers: [RoutingService]
})
export class WellDetailsAdminComponent implements OnInit {
  admins: IUser[] | undefined;
  isEmtpy: boolean | undefined;

  constructor(private readonly _activatedRoute: ActivatedRoute, private readonly _backendService: BackendService) {}

  ngOnInit(): void {
    this.loadAdmins();
  }

  loadAdmins(): void {
    let id: number = parseInt(this._activatedRoute.snapshot.paramMap.get('id') ?? "-1");
    if (id == -1)
      return;

    this._backendService.GetWellRequestAdmins(id?.toString()).subscribe((admins: IUser[]) => {
      this.admins = admins.map(admin => {
        admin.name = admin.firstName + " " + admin.lastName
        admin.email = "mailto:" + admin.email
        return admin;
      });

      this.isEmtpy = this.admins?.length === 0;
    });
  }
}
