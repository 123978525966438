<div class="mat-input-d-3">
  <mat-label>{{ labelItem }}</mat-label><br />
  <mat-form-field class="white-form-field"
                  style="width: 500px"
                  appearance="fill"
                  subscriptSizing="dynamic">
    <mat-chip-grid #chipGrid
                   [formControl]="chipCtrl"
                   app-autocomplete-validator
                   required>
      <mat-chip-row [removable]="isRemovableFunc(item)" class="white-chip" *ngFor="let item of selectedItems" (removed)="remove(item)">
        {{item.name}}
        <button *ngIf="isRemovableFunc(item)" matChipRemove [attr.aria-label]="'remove ' + item.name">
          <mat-icon>cancel</mat-icon>
        </button>
      </mat-chip-row>
      <input matInput 
             type="text"
             [placeholder]="placeholderItem"
             [formControl]="chipCtrl"
             [(ngModel)]="value"
             [matChipInputFor]="chipGrid"
             [matAutocomplete]="auto"
             [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
             #chipInput />
      <mat-autocomplete #auto="matAutocomplete" [displayWith]="displayFn" hideSingleSelectionIndicator="True" (opened)="onOpened()" (optionSelected)="selected($event)">
        <cdk-virtual-scroll-viewport [ngStyle]="{height: '192px'}"
                                     itemSize="48"
                                     minBufferPx="200"
                                     maxBufferPx="400"
                                     #matAutocompleteViewport>
          <mat-option *cdkVirtualFor="let item of items" [value]="item">
            {{item.name}}
          </mat-option>
        </cdk-virtual-scroll-viewport>
      </mat-autocomplete>
    </mat-chip-grid>
  </mat-form-field>
</div>
