import { Component, OnInit } from '@angular/core';
import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';
import { IconProvider } from 'src/app/providers/icon-provider';
import { AuthService } from 'src/app/services/auth.service';
import { Router } from '@angular/router';
import { PermissionsService } from 'src/app/services/permissions.service';

@Component({
  selector: 'app-access-denied',
  templateUrl: './access-denied.component.html',
  styleUrls: ['./access-denied.component.css']
})
export class AccessDeniedComponent implements OnInit {
  isVisible: boolean | undefined;

  constructor(
    private readonly _authService: AuthService,
    private readonly _permissionsService: PermissionsService,
    private readonly _router: Router,
    iconRegistry: MatIconRegistry,
    sanitizer: DomSanitizer) {
      iconRegistry.addSvgIconLiteral("login-error", sanitizer.bypassSecurityTrustHtml(IconProvider.LOGIN_ERROR));
  }

  ngOnInit(): void {
    this._permissionsService.GetUserPermission().subscribe({
      next: () => {
        this._router.navigate(["/"]);
      },
      error: (err) => {
        if (err.status == 403) {
          this.isVisible = true;
          setTimeout(() => this.signIn(), 15000);
        }
      }
    });
  }

  signIn(): void {
    this._authService.logout();
  }
}
