import { Injectable } from '@angular/core';
import { WellRequestStatus_Options } from '../model/status-enum.model';
import { IStatus } from '../model/status.model';
import { BehaviorSubject, Observable, ReplaySubject } from 'rxjs';
import { PermissionsService } from './permissions.service';

@Injectable({
  providedIn: 'root'
})
export class StatusHelperService {

  private _statusItemSource = new ReplaySubject<WellRequestStatus_Options>();

  statusItem$: Observable<WellRequestStatus_Options>;
  constructor() {
    this.statusItem$ = this._statusItemSource.asObservable();
  }

  changeStatus(status: WellRequestStatus_Options) {
    this._statusItemSource.next(status);
  }

  public static GetStatus(wellStatusOption: WellRequestStatus_Options | undefined): IStatus {
    switch (wellStatusOption) {
      case WellRequestStatus_Options.Created:
      case WellRequestStatus_Options.Resubmited:
      case WellRequestStatus_Options.Request_Changes:
      case WellRequestStatus_Options.Approved:
        return { id: wellStatusOption, name: 'Pending Creation', color: 'orange' };
      case WellRequestStatus_Options.Cancelled_By_User:
      case WellRequestStatus_Options.Cancelled_By_Admin:
        return { id: wellStatusOption, name: 'Inactive', color: 'grey' };
      case WellRequestStatus_Options.ApprovedActive:
        return { id: wellStatusOption, name: 'Active', color: 'rgb(63, 194, 37)' }
      case WellRequestStatus_Options.ApprovedInactive:
        return { id: wellStatusOption, name: 'Inactive', color: 'grey' };
    }
    return { id: wellStatusOption, name: 'Pending Creation', color: 'orange' };
  }

  public static GetStatusList(wellStatusOption: WellRequestStatus_Options | undefined, isAdmin: boolean): IStatus[] {

    switch (wellStatusOption) {
      case WellRequestStatus_Options.Created:
      case WellRequestStatus_Options.Resubmited:
      case WellRequestStatus_Options.Request_Changes:
      case WellRequestStatus_Options.Approved:
        return [{ id: wellStatusOption, name: 'Pending Creation', color: 'orange' }];
      case WellRequestStatus_Options.Cancelled_By_User:
      case WellRequestStatus_Options.Cancelled_By_Admin:
        return [{ id: wellStatusOption, name: 'Inactive', color: 'grey' }];
      case WellRequestStatus_Options.ApprovedActive:
        if (isAdmin)
          return [
            { id: WellRequestStatus_Options.ApprovedActive, name: 'Active', color: 'rgb(63, 194, 37)' },
            { id: WellRequestStatus_Options.ApprovedInactive, name: 'Inactive', color: 'grey' },
            { id: WellRequestStatus_Options.Request_Changes, name: 'Pending Creation', color: 'orange', disabled: true }
          ]
        else
          return [{ id: WellRequestStatus_Options.ApprovedActive, name: 'Active', color: 'rgb(63, 194, 37)' }]
      case WellRequestStatus_Options.ApprovedInactive:
        if (isAdmin)
          return [
            { id: WellRequestStatus_Options.ApprovedActive, name: 'Active', color: 'rgb(63, 194, 37)' },
            { id: WellRequestStatus_Options.ApprovedInactive, name: 'Inactive', color: 'grey' },
            { id: WellRequestStatus_Options.Request_Changes, name: 'Pending Creation', color: 'orange', disabled: true }
          ]
        else
          return [{ id: WellRequestStatus_Options.ApprovedInactive, name: 'Inactive', color: 'grey' }]
    }
    return [];
  }

  public static GetHrefByStatus(wellStatusOption: WellRequestStatus_Options | undefined, isAdmin: boolean): string {
    switch (wellStatusOption) {
      case WellRequestStatus_Options.Created:
      case WellRequestStatus_Options.Resubmited:
        return '/well-request/approve/'
      case WellRequestStatus_Options.Request_Changes:
        return  isAdmin ? '/well-request/view/' : '/well-request/edit/';           
      case WellRequestStatus_Options.Approved:
      case WellRequestStatus_Options.Cancelled_By_User:
      case WellRequestStatus_Options.Cancelled_By_Admin:
        return '/well-request/view/'
      case WellRequestStatus_Options.ApprovedActive:
      case WellRequestStatus_Options.ApprovedInactive:
        return isAdmin ? '/well-request/review/' : '/well-request/view/';
      default:
      return '';
    }
  }
}
