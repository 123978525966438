import {Directive} from '@angular/core';
import {AbstractControl, NG_VALIDATORS, ValidationErrors, Validator, ValidatorFn} from '@angular/forms';

export function selectedValidator(): ValidatorFn {
  return (control: AbstractControl): ValidationErrors | null => {
    const forbidden = typeof control.value !== 'object';
    return forbidden ? { forbidden: { value: control.value } } : null;
  };
}

@Directive({
    selector: "[app-autocomplete-validator]",
    providers: [{
        provide: NG_VALIDATORS,
        useExisting: AutocompleteValidatorDirective,
        multi: true
    }]
})
export class AutocompleteValidatorDirective implements Validator {

    validate(control: AbstractControl): ValidationErrors | null {
        return selectedValidator()(control);
    }
}