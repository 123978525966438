<div class="access-denied-wrap" *ngIf="isVisible">
  <div class="access-denied-container">
    <mat-grid-list cols="3">
      <mat-grid-tile>
        <div class="login-error-icon-container">
          <mat-icon svgIcon="login-error" aria-hidden="false" class="login-error-icon"></mat-icon>
        </div>
      </mat-grid-tile>
  
      <mat-grid-tile colspan="2">
        <div class="login-error-container">
          <div>
            <h3 class="mat-headline-3 access-denied">Access Denied</h3>
          </div>
          <div class="message-contatner">
            <span>The entered credentials require <strong>VIDA validation to access<br/> this URL.</strong> Please ensure you have entered the <strong>the correct<br/> credentials</strong> on the platform.</span>
          </div>
          <div class="sign-in-button-container">
            <a mat-raised-button type="button" color="primary" class="sign-in-button" style="background-color: #0099fa;" (click)="signIn()">SIGN IN  ></a>
          </div>
        </div>
      </mat-grid-tile>
    </mat-grid-list>
  </div>
</div>

