import { WellRequestComponent } from "../well-request.component";
import { ICountry } from "../../../model/country.model";
import { WellRequestBaseHandler } from "./well-request-handler-base";

export class WellRequestCountryHandler extends WellRequestBaseHandler {
    private oldCountry: ICountry | undefined;
    constructor(wellRequestComponent: WellRequestComponent) {
        super(wellRequestComponent);
    }

    async countryHandler(countryData: any) {
      if (typeof countryData === 'string')
        await this.countryCleared(countryData);
      else
        await this.countryChange(countryData);
    }

    private async countryChange(country: ICountry) {
        let isCountryChanged = this.wellRequestComp.previousCountryId
            && this.wellRequestComp.previousCountryId
            != country.id;

        if (isCountryChanged
            && this.wellRequestComp.selectedAdmins.length > 0) {
            const userConfirmed = await this.openDialogUpdate().toPromise();
            if (!userConfirmed) {
                this.wellRequestComp.countryControl.patchValue(this.oldCountry, { emitEvent: false });
                return;
            }
        }

        if (country.regionId && country.regionId
            !== this.wellRequestComp.regionControl.value.idsRegionChanged)
            this.patchRegion(country.regionId.toString());

        if (!this.wellRequestComp.previousCountryId)
            this.oldCountry = country;

        if (isCountryChanged) {
            this.oldCountry = country;
            this.resetGroups();
        }

        this.wellRequestComp.filteredGroups = this.isCountryMapped(Number(country.id))
          ? this.wellRequestComp.groups.filter(x => x.countryId == country.id)
          : this.wellRequestComp.groups.filter(x => x.regionId == country.regionId);

        this.getRigsByCountry(country); 
        this.countryFilter(country.name ?? '', country.regionId ?? -1);

        this.wellRequestComp.previousCountryId = country.id;
    }

    private async countryCleared(country: string) {
        let isEmpty = false;
        let isRegionChanged = this.wellRequestComp.regionControl.value.id ?
            this.oldCountry?.regionId !== this.wellRequestComp.regionControl.value.id
            : this.wellRequestComp.regionControl.value == '';

        isEmpty = country == '';

        if (isEmpty
            && this.wellRequestComp.selectedAdmins.length > 0
            && !isRegionChanged) {
            const userConfirmed = await this.openDialogUpdate().toPromise();
            if (!userConfirmed) {
                this.wellRequestComp.countryControl.patchValue(this.oldCountry, { emitEvent: false });
                return;
            }
        }

        if (this.wellRequestComp.regionControl.value.id) {
            this.countryFilter(country, this.wellRequestComp.regionControl.value.id);
            this.getRigsByRegion(this.wellRequestComp.regionControl.value.id);
        }

        this.wellRequestComp.filteredGroups = [];

        if (isEmpty) {
            this.resetGroups();
            this.wellRequestComp.filteredGroups = this.wellRequestComp.groups
                .filter(x => x.regionId == this.wellRequestComp.regionControl.value.id);
            this.wellRequestComp.previousCountryId = '';
        }
    }

    private patchRegion(regionId: string): void {
        const region = this.wellRequestComp.regions.find(x => x.id == regionId);
        this.wellRequestComp.regionControl.patchValue({ id: region?.id, name: region?.code }, { emitEvent: false });
    }
}
