<div class="mat-input-d-3">
  <mat-label class="label">Status</mat-label>
  <mat-form-field style="width:180px;"
                  class="status-background status-background-disabled"
                  appearance="fill"
                  subscriptSizing="dynamic">
    <mat-select [disabled]="disabledField"
                (selectionChange)="change($event)"
                [hideSingleSelectionIndicator]="true"
                [(value)]="preselectedStatus"
                [compareWith]="compareFn"
                class="select">
      <mat-select-trigger>
        <div>
          <app-status-icon [status]="preselectedStatus"></app-status-icon>
        </div>
      </mat-select-trigger>
      <mat-option *ngFor="let status of statusOptions" [value]="status" [disabled]="status?.disabled">
        <mat-icon class="status-icon" [ngStyle]="{'color': status?.color}">brightness_1</mat-icon>
        <span class="name-status">{{ status.name }}</span>
      </mat-option>
    </mat-select>
  </mat-form-field>
</div>
