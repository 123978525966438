import { Component, OnInit } from '@angular/core';
import { BackendService } from '../../../services/backend.service';
import { WellRequestLog } from '../../../model/well-request-log.model';
import { RoutingService } from '../../../services/routing.service';

@Component({
  selector: 'app-well-details-comments',
  templateUrl: './well-details-comments.component.html',
  styleUrls: ['../well-details.component.css'],
  providers: [RoutingService]
})
export class WellDetailsCommentsComponent implements OnInit {
  wellReuqestLog: WellRequestLog | undefined

  constructor(private _backendService: BackendService, private _routingService: RoutingService) { }

  ngOnInit() {
    this._backendService.getWellRequestLog(this._routingService.getWellRequestId()).subscribe(data => {
      this.wellReuqestLog = data
    });
  }
}
