import { IAdmin } from "../model/admin.model";
import { IGroup } from "../model/group.model";
import { WellRequestAdmin } from "../model/well-request-admin.model";

declare global {
  interface Array<T> {
    getAdmins(adminsId: WellRequestAdmin[]): IAdmin[];
    getWellRequestAdmins(wellRequestGroups: IGroup[]): WellRequestAdmin[];
    exceptAdmins(admins: IAdmin[]): string[];
    equalAdmins(admins: IAdmin[]): boolean;
  }
}

Object.defineProperty(Array.prototype, "getAdmins", {
  value(this: IAdmin[], adminsId: WellRequestAdmin[]):IAdmin[] {
    return this.filter(admin => admin.adminGroups &&
      admin.adminGroups.some(adGroup => adminsId.some(a => a.adminId == adGroup.id)))
    .reduce((acc: IAdmin[], curr) => {
      if (!acc.map(a => a.id).includes(curr.id)) {
        curr.isTookAction = adminsId.find(x => curr.adminGroups?.find(f => f.id == x.adminId))?.isTookAction
        acc.push(curr);
      }
      return acc;
    }, []);
  },
  enumerable: false
});
export { };

Object.defineProperty(Array.prototype, "getWellRequestAdmins", {
  value(this: IAdmin[], wellRequestGroups: IGroup[]): WellRequestAdmin[] {
    return this.flatMap(x => x.adminGroups)
      .filter(ag => ag && wellRequestGroups.some(g => g.id === ag.groupId))
      .map(ag => ({
        adminId: ag?.id,
        isTookAction: this.find(t => t.adminGroups?.map(a => a.id).includes(ag?.id))?.isTookAction ?? false,
      })); 
  },
  enumerable: false
});
export { };

Object.defineProperty(Array.prototype, "exceptAdmins", {
  value(this: IAdmin[], admins: IAdmin[]): string[] {
    return this.filter(x => x.id && !admins
      .map(a => a.id)
      .includes(x.id)).map(admin => admin.id) as string[];
  },
  enumerable: false
});
export { };

Object.defineProperty(Array.prototype, "equalAdmins", {
  value(this: IAdmin[], admins: IAdmin[]): boolean {
    if (this.length != admins.length)
      return false;

    let list1 = this.slice().map(x => x.id).sort()
    let list2 = admins.slice().map(x => x.id).sort()

    for (let i = 0; i < list1.length; i++) {
      if (list1[i] !== list2[i]) 
        return false;
      }

      return true;
  },
  enumerable: false
});
export { }; 

