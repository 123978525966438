import { Component, AfterViewInit, ViewChild } from '@angular/core';
import { WellRequestTableComponent } from './well-request-table.component';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { RoutingService } from '../../services/routing.service';
import { BackendService } from '../../services/backend.service';

@Component({
  selector: 'app-well-request-table-all',
  templateUrl: './well-request-table.component.html',
  styleUrls: ['./well-request-table.component.css'],
  providers: [RoutingService]
})
export class WellRequestTableAllComponent extends WellRequestTableComponent implements AfterViewInit {
  @ViewChild(MatPaginator) set setPaginator(value : MatPaginator ) {
    this.paginator = value;
  };
  @ViewChild(MatSort) set setSort(value: MatSort) {
    this.sort = value;
  }

  displayedColumns = ['id', 'wellName', 'userName', 'date', 'status'];
  header: string = 'All Requests';

  constructor(routingService: RoutingService, backendService: BackendService, ) {
    super(backendService, routingService);
  }

  ngAfterViewInit() {
    this.intiPaginator();
  }
}
