import { Directive, ElementRef, HostListener, Input } from '@angular/core';
import { Clipboard } from '@angular/cdk/clipboard';

@Directive({
  selector: '[decimaNumber]'
})
export class DecimaNumberDirective {
  private _regexp: RegExp | undefined
  private _special: Array<string> = ["Backspace", "Tab", "End", "Home", "ArrowLeft", "ArrowRight", "Del", "Delete"];
  @Input('decimaNumber') option: any;

  constructor(private readonly _el: ElementRef, private clipboard: Clipboard) {
  }

  @HostListener('paste', ['$event'])
  onPaste(event: ClipboardEvent) {
    this.setRegExp();

    if (!this._regexp)
      return;

    let current = event.clipboardData?.getData('text');
    let value = this.copyAndPasteValue(current ?? '');

    if (value && !String(value).match(this._regexp)) {
      event.preventDefault();
    }
  }

  @HostListener('keydown', ['$event'])
  async onKeyDown(event: KeyboardEvent) {
    this.setRegExp();

    if (this._special.indexOf(event.key) !== -1 || !this._regexp)
      return;

    if ((event.ctrlKey || event.metaKey) && (event.key == 'c' || event.key == 'C')) {
      this.clipboard.copy(this.copyAndPasteValue(''));
      return;
    }

    let isPaste: boolean = false;
    let value: string;
    let elValue: string = this._el.nativeElement.value;
    let startPosition: any = this._el.nativeElement.selectionStart;
    let endPosition: any = this._el.nativeElement.selectionEnd;
    let current: string = event.key == 'Decimal' ? '.' : event.key;

    if ((event.ctrlKey || event.metaKey) && (event.key == 'v' || event.key == 'V')) {
      isPaste = true;
      current = await navigator.clipboard.readText();
    }

    if (startPosition !== endPosition || isPaste) 
      value = elValue.slice(0, startPosition) + current + elValue.slice(endPosition);
    else {
      let start: string = elValue.slice(0, startPosition);
      let end: string = elValue.slice(startPosition);
      value = [start, current, end].join('');
    }

    if (value && !String(value).match(this._regexp)) {
      event.preventDefault();
    }
  }

  copyAndPasteValue(current:string):string {
    let elValue: string = this._el.nativeElement.value;
    let startPosition: any = this._el.nativeElement.selectionStart;
    let endPosition: any = this._el.nativeElement.selectionEnd;
    return elValue.slice(0, startPosition) + current + elValue.slice(endPosition);
  }

  private setRegExp(): void {
    if (!this._regexp)
      this._regexp = new RegExp("^-?\\d*\\.?\\d{0,"+ this.option +"}$", "g");
  }
}
