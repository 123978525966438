import { AfterViewInit, Component, ElementRef, Inject, ViewChild } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { PopupData } from '../../../model/popup.model';

@Component({
  selector: 'app-submit-popup',
  templateUrl: './submit-popup.component.html',
  styleUrls: ['./submit-popup.component.css']
})
export class SubmitPopupComponent implements AfterViewInit {
  popupText: PopupData | undefined;
  dialogTitle: string | undefined;
  infoBlock:  string | undefined;
  @ViewChild('contentContainer', {static: false}) contentContainer!: ElementRef<HTMLDivElement> ;
  constructor(@Inject(MAT_DIALOG_DATA) data: { popupData: PopupData }) {
    this.popupText = data.popupData;
    this.infoBlock = this.popupText.textInfo; 
  }

  ngAfterViewInit(): void {
    if (this.contentContainer && this.infoBlock) {
      this.contentContainer.nativeElement.innerHTML = this.infoBlock;
    }
  }
}
