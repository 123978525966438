import { Component, Input } from '@angular/core';
import { WellRequest } from 'src/app/model/well-request.model';
import { Router } from '@angular/router';
import { RoutingService } from '../../../services/routing.service';

@Component({
  selector: 'app-well-details-header',
  templateUrl: './well-details-header.component.html',
  styleUrls: ['../well-details.component.css'],
  providers: [RoutingService]
})
export class WellDetailsHeaderComponent {
 
  @Input()
  wellRequest!: WellRequest | undefined;

  constructor(private readonly _router: Router) {}

  openHistoryLog(): void {
    this._router.navigate(['/well-request/log', this.wellRequest?.id]);
  }
}
