<div class="submit-container">
  <h1 mat-dialog-title><strong>{{popupText?.header}}</strong></h1>
  <mat-dialog-content>
      <div #contentContainer>
      </div>
    </mat-dialog-content>
  <mat-dialog-actions style="float:right">
    <button mat-button mat-dialog-close style="color:red">NO, GO BACK</button>
    <button mat-button [mat-dialog-close]="true" style="color:dodgerblue" cdkFocusInitial>{{popupText?.textSubmit}}</button>
  </mat-dialog-actions>
</div>

