<app-back-button></app-back-button>

<div class="details-container" *ngIf="wellRequest$ | async as wellRequest">
  <app-well-details-header [wellRequest]="wellRequest"></app-well-details-header>
  <div class="cancel-button" *ngIf="canCancel()">
    <app-cancel-request-button *ngIf="!wellRequest?.isInRts" [wellRequestId]="wellRequest?.id"></app-cancel-request-button>
  </div>

  <app-well-details-admin></app-well-details-admin>
  <mat-divider></mat-divider>
  <app-well-details-info [wellRequest]="wellRequest"></app-well-details-info>
  <div *ngIf="isCanEdit">
    <form class="form" [formGroup]="form">
      <div class="well-info-item">
        <div style="margin: 0px 0px -28px 50px; position: relative;">
          <mat-icon matTooltip="A group name selection is needed to enable admin list" style="font-size: 21px; color: blue">info</mat-icon>
        </div>
        <app-chip #chipComponent label="Admin"
                  placeholder="Enter admin..."
                  (selectedItemsEvent)="adminSelectionChanged($event)"
                  (removeEvent)="adminRemoveChanged($event)"
                  [count]=5
                  [dataSource]="filteredAdmins"
                  [selectedSourceItems]="selectedAdmins"
                  formControlName="admin"
                  app-autocomplete-validator
                  required>
        </app-chip>
      </div>
    </form>
  </div>
  <app-well-details-comments></app-well-details-comments>
  <app-submit-cancel-button *ngIf="isCanEdit" [disableSubmit]="isDisableSubmit" [form]="form" (submitedEvent)="submitHandler($event)"></app-submit-cancel-button>
</div>
