import { OnInit, Injectable } from '@angular/core';
import { MatTableDataSource} from '@angular/material/table';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { Router } from '@angular/router';
import { BackendService } from '../../services/backend.service';
import { IWellRequestTable } from '../../model/well-request-table.model';
import { RoutingService } from '../../services/routing.service';

@Injectable()
export abstract class WellRequestTableComponent implements OnInit {
  dataSource = new MatTableDataSource<IWellRequestTable>();

  protected paginator!: MatPaginator;
  protected sort!: MatSort;

  constructor(protected backendService: BackendService, private routingService: RoutingService) {
  }

  ngOnInit(): void {
    this.loadWells();
    this.activeRouteTab();
  }

  protected intiPaginator() {
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
  }

  getRecord(row: IWellRequestTable) {
    this.routingService.forwardRedirect(row.id.toString())
  }

  activeRouteTab() { this.routingService.setRoute(this.routingService.router.url) }
  
  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();

    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }

  loadWells(): void {
    this.backendService.getWellRequestTable().subscribe(data => {
      this.dataSource.data = data;
    })
  }
}
